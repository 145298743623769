import React from 'react';

export const MiningIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.9 124.8" aria-hidden>
    <g>
      <path className="industry-icon-color-fill" d="m22.6 36 15-15 12 12-15 15z" />
      <path fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" d="m22.6 36 15-15 12 12-15 15z" />
      <path fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" d="m38.4 44.8 7.9-7.8L96 86.7 88 94.5z" />
      <path d="M24.2 37.7s-24 24-19.8 56.6l5.4-1S8.2 70.2 32.4 46" fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" />
      <path d="M39.2 22.7s24-24 56.6-19.8l-1 5.4S71.5 6.7 47.3 31" fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" />
      <path d="m.4 116.3 19-3.1 14.2-17.7 9 3.7L57.3 121l14.3-4.2 17.1 4.2 21.7-15 6.9 2.2" fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" />
      <path fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" d="m19.4 113.2 11.3 9.8" />
      <path d="m35.2 80.2-7.4 7.1-3.9-2.8L29 74Z" fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="4.8" />
    </g>
  </svg>
);

export const AgricultureIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.1 95.7" aria-hidden>
    <g fill="none" className="industry-icon-color-stroke" strokeMiterlimit="10" strokeWidth="3.4">
      <path d="M14.3 5.4C9 2.1 1.7 2.7 1.7 2.7S1.2 10 4.5 15.2s8.7 5 11.7 2 3.3-8.5-2-11.8Z" />
      <path d="M22.5 5.4c5.3-3.3 12.6-2.7 12.6-2.7s.5 7.3-2.8 12.5-8.7 5-11.7 2-3.3-8.5 2-11.8Z" />
      <path d="M14.3 26.4C9 23.1 1.7 23.7 1.7 23.7S1.2 31 4.5 36.2s8.7 5 11.7 2 3.3-8.5-2-11.8Z" />
      <path d="M22.5 26.4c5.3-3.3 12.6-2.7 12.6-2.7s.5 7.3-2.8 12.5-8.7 5-11.7 2-3.3-8.5 2-11.8Z" />
      <path d="M14.3 47.4C9 44.1 1.7 44.7 1.7 44.7S1.2 52 4.5 57.2s8.7 5 11.7 2 3.3-8.5-2-11.8Z" />
      <path d="M22.5 47.4c5.3-3.3 12.6-2.7 12.6-2.7s.5 7.3-2.8 12.5-8.7 5-11.7 2-3.3-8.5 2-11.8Z" />
      <path d="M14.3 68.4C9 65.1 1.7 65.7 1.7 65.7S1.2 73 4.5 78.2s8.7 5 11.7 2 3.3-8.5-2-11.8Z" />
      <path d="M22.5 68.4c5.3-3.3 12.6-2.7 12.6-2.7s.5 7.3-2.8 12.5-8.7 5-11.7 2-3.3-8.5 2-11.8Z" />
      <path d="M17 1.7h2.8V94H17z" />
      <path d="M59.5 73.1v-54h5.8v54" />
      <path d="M63 94h-1.3a13.4 13.4 0 0 1-13.4-13.3V62.1h28.1v18.6a13.4 13.4 0 0 1-13.3 13.4Z" />
      <path d="M54.3 1.7v10.7a7.7 7.7 0 0 0 7.7 7.7h.8a7.7 7.7 0 0 0 7.7-7.7V1.7Zm13.6 10.2a5.2 5.2 0 0 1-5.2 5.2H62a5.2 5.2 0 0 1-5.2-5.2V4.7h11Z" />
    </g>
  </svg>
);

export const TantalumIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138 83.9">
    <path d="m133 17.8-53.6 4.4L28.9 5 24.2 17 4.4 27.5l4.4 27.4 9.7 5.6-.4 19.4h73.5l34.8-29.5Z" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="5" />
    <path d="m29 5 16 37.4 56.9-3 29.3-13 1.8-8.6-53.6 4.4Z" fill="#faf6ef" />
    <g fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="5">
      <path d="m50.9 37-21 15.8" />
      <path d="m90.7 79.9-4-31.5" />
    </g>
  </svg>
);

export const SesameIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.5 93.3">
    <g
      fill="none"
      stroke="#faf6ef"
      strokeMiterlimit="10"
      strokeWidth="5"
    >
      <path
        d="M5 9S-6 29 19 64c17 23 35 38 61 16 22-26 7-44-16-61C29-6 10 5 10
        5H9a8 8 0 0 0-4 4Z"
      />
      <path
        d="M18 37s34 67 65 37"
      />
    </g>
    <path
      d="M75 83s13-20-7-39C55 31 28 10 3 14c15-31 73 8 84 25 11 18-3 44-12 44"
      fill="#faf6ef"
    />
  </svg>
);

export default { MiningIcon, AgricultureIcon };
