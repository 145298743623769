import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import './download-icon.scss';

const DownloadIcon = () => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 1 });
  return (
    <svg
      className={`download-icon${isRevealed ? ' is-visible' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.1 132.1"
      ref={ref}
    >
      <path d="M42.4 8.8a62 62 0 1 0 23.7-4.6v56.5" className="download-icon-path1" />
      <g className="download-icon-down">
        <path d="M40.6 59.5h51L66 88.8Z" className="download-icon-path2" />
      </g>
    </svg>
  );
};

export default DownloadIcon;
