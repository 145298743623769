import React, { useContext } from 'react';
import IndustryContext from '../industry-context';

import { MiningIcon, AgricultureIcon } from './icons';

import './industry-selector.scss';

const IndustrySelector = () => {
  const { industry, setIndustry } = useContext(IndustryContext);

  return (
    <div className={`industry-selector is-${industry}-selected`}>
      <button
        className="industry-selector-option industry-selector-option-agriculture"
        type="button"
        onClick={() => setIndustry('agriculture')}
      >
        {AgricultureIcon}
        Agriculture
      </button>
      <button
        className="industry-selector-option industry-selector-option-mining"
        type="button"
        onClick={() => setIndustry('mining')}
      >
        {MiningIcon}
        Mining
      </button>
    </div>
  );
};

export default IndustrySelector;
