import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import './where-is-used.scss';

interface WhereIsUsedDiagramResource {
  name: string
  icon: React.ReactNode
  description: string
}

interface WhereIsUsedDiagramProps {
  children: React.ReactNode
  title: string
  icons: WhereIsUsedDiagramResource[]
}

const WhereIsUsedDiagram = (
  { children, title, icons } : WhereIsUsedDiagramProps,
) => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 0.5 });
  return (
    <div
      className={`where-is-used${isRevealed ? ' is-revealed' : ''}`}
      ref={ref}
    >
      <h3 className="where-is-used-title">{title}</h3>
      <div className="where-is-used-content">
        {children}
      </div>
      <ul className="where-is-used-icons">
        {icons.map(({ name, icon, description }) => (
          <li className="where-is-used-icons-item" key={name}>
            <div className="where-is-used-icons-item-contents" title={name}>
              {icon}
            </div>
            <div className="where-is-used-icons-item-description">
              {description}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhereIsUsedDiagram;
