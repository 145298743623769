import React, { useContext, useState, useEffect } from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import { GroupingElementMining, GroupingElementFarming } from './grouping-element';
import EnvironmentSign from './environment-sign';
import MainCircle from './main-circle';
import {
  IconHands, IconHandStar, IconBuilding, IconDocs, IconNotepad, IconBaby,
  IconPolice, IconGlobe, IconSoldier, IconGun, IconFamily, IconDigger,
  IconWasher, IconSmallTrader, IconTrader, IconProcessor, IconInformalExporter,
  IconCommerce, IconLabour, IconCoins, IconAgroIndustry, IconStreetTraders,
  IconResearchDevelopment, IconTransport, IconSeedSupplier, IconIndustrialFarms,
  IconUnions, IconSesameProcessors, IconImportExport, IconCrusher, IconFarmer,
  IconFormalExporter, IconHandsAlt,

} from './icons';
import CloseButton from './close-button';

import './local-level-diagram.scss';
import InterventionIndicator from './intervention-indicator';
import CircularDiagram from '../circular-diagram';
import { textContentFunction } from '../../utils/text';
import IndustryContext from '../industry-context';

type IconSet = [React.ReactElement, string, string, string];

const OUTER_ICONS: IconSet[] = [
  [IconHands, 'hands', 'Cooperatives', 'Tantalum Local Cooperatives Description'],
  [IconHandStar, 'hand-star', 'Community leaders', 'Tantalum Local Community Leaders Description'],
  [IconBuilding, 'building', 'Government agencies', 'Tantalum Local Government Description'],
  [IconDocs, 'docs', 'Concession owners', 'Tantalum Local Concession Owners'],
  [IconNotepad, 'notepad', 'Schools', 'Tantalum Local Schools Description'],
  [IconBaby, 'baby', 'CSOs & child protection workers', 'Tantalum Local Child Protection Description'],
  [IconPolice, 'police', 'Mine & local police', 'Tantalum Local Mine and Local Police'],
  [IconGlobe, 'globe', 'NGOs', 'Tantalum Local NGOs'],
  [IconSoldier, 'soldier', 'FARDC', 'Tantalum Local FARDC'],
  [IconGun, 'gun', 'Armed groups', 'Tantalum Local Armed Groups Description'],
  [IconFamily, 'family', 'Parents', 'Tantalum Local Parents Description'],
];

const SESAME_OUTER_ICONS: IconSet[] = [
  [IconCoins, 'coins', 'Banks/ Finance', 'Sesame Local Banks and Finance'],
  [IconBuilding, 'building', 'Government agencies', 'Sesame Local Government Description'],
  [IconAgroIndustry, 'agro', 'Agro-Industry parks', 'Sesame Local Agro-Industry Parks'],
  [IconResearchDevelopment, 'randd', 'R&D', 'Sesame Local R&D'],
  [IconNotepad, 's-notepad', 'Schools', 'Sesame Local Schools'],
  [IconFamily, 's-family', 'Parents', 'Sesame Local Parents'],
  [IconHandStar, 's-hand-star', 'Community leaders', 'Sesame Local Community Leaders'],
  [IconStreetTraders, 'street-traders', 'Street traders & sexual services', 'Sesame Local Street Traders and Sexual Services'],
  [IconBaby, 's-baby', 'Child protection workers', 'Sesame Local Child Protection Workers'],
];

const INNER_ICONS: IconSet[] = [
  [IconDigger, 'digger', 'Digger', 'Tantalum Local Digger Description'],
  [IconCrusher, 'crusher', 'Crusher', 'Tantalum Local Crusher Description'],
  [IconWasher, 'washer', 'Washer', 'Tantalum Local Washer and Sorter Description'],
  [IconSmallTrader, 'small-trader', 'Small Trader', 'Tantalum Local Small Trader Description'],
  [IconTrader, 'trader', 'Trader', 'Tantalum Local Trader Negociant Description'],
  [IconProcessor, 'processor', 'Processor', 'Tantalum Local Processor Description'],
  [IconInformalExporter, 'informal-exporter', 'Informal Exporter', 'Tantalum Local Informal Exporter Description'],
  [IconFormalExporter, 'formal-exporter', 'Formal Exporter', 'Tantalum Local Formal Exporter Description'],
];

const SESAME_INNER_ICONS: IconSet[] = [
  [IconSeedSupplier, 'supplier', 'Seed Supplier', 'Sesame Local Seed and Fertiliser Suppliers'],
  [IconFarmer, 'smallholder', 'Smallholder Farmers', 'Sesame Local Smallholder Farmer'],
  [IconIndustrialFarms, 'industrial-farm', 'Industrial farms', 'Sesame Local Large-Scale Producers'],
  [IconTrader, 's-trader', 'Trader', 'Sesame Local Traders and Aggregators'],
  [IconHandsAlt, 'coops', 'Primary Cooperatives', 'Sesame Local Primary Cooperatives'],
  [IconUnions, 'unions', 'Cooperative Unions', 'Sesame Local Cooperative Unions'],
  [IconSesameProcessors, 'processors', 'Sesame Processors', 'Sesame Local Sesame Processors'],
  [IconImportExport, 'import-export', 'Export Companies', 'Sesame Local Import / Export Companies'],
];

const BOTTOM_ICONS: IconSet[] = [
  [IconCommerce, 'commerce', 'Small commerce & sexual services', 'Tantalum Local Small Commerce Description'],
  [IconLabour, 'labour', 'Labour recruitment', 'Tantalum Local Labour Recruitment Description'],
];

const SESAME_BOTTOM_ICONS: IconSet[] = [
  [IconTransport, 'transport', 'Transport', 'Sesame Local Transport'],
  [IconLabour, 's-labour', 'Labour recruitment', 'Sesame Local Labour Recruitment'],
];

const AFFECTED_BY = [
  ['digger', 'washer', 'small-trader', 'trader', 'formal-exporter', 'commerce',
    'labour', 'hands', 'gun', 'building'],
  ['digger', 'washer', 'commerce', 'gun', 'hand-star', 'notepad', 'family',
    'baby', 'building'],
];

const INTERVENTION_TEXT = [
  'Developing a Comprehensive Mining Sector: Smelter',
  'Alternative pathways: Digital skills training & employment opportunities',
];

const SESAME_INTERVENTION_TEXT = [
  'Agricultural Business Clustering',
  'A World Class Approach to Child Labour in Sesame',
  'Health Education and Community Awareness Raising',
];

interface interventionDescriptions {
  [key: string]: {
    description: string
  }
}
const INTERVENTION_DESCRIPTIONS: [interventionDescriptions, interventionDescriptions] = [{
  Intro: {
    description: 'Tantalum Intervention 1 Smelter Introduction',
  },
  Digger: {
    description: 'Tantalum Intervention 1 Smelter Digger',
  },
  Washer: {
    description: 'Tantalum Intervention 1 Smelter Washers and Sorters',
  },
  'Small Trader': {
    description: 'Tantalum Intervention 1 Smelter Small Traders',
  },
  Trader: {
    description: 'Tantalum Intervention 1 Smelter Trader Negociant',
  },
  'Formal Exporter': {
    description: 'Tantalum Intervention 1 Smelter Formal Exporters',
  },
  'Small commerce & sexual services': {
    description: 'Tantalum Intervention 1 Smelter Small Traders',
  },
  'Labour recruitment': {
    description: 'Tantalum Intervention 1 Smelter Labour Recruitment',
  },
  Cooperatives: {
    description: 'Tantalum Intervention 1 Smelter Cooperatives',
  },
  'Armed groups': {
    description: 'Tantalum Intervention 1 Smelter Armed Groups',
  },
  'Government agencies': {
    description: 'Tantalum Intervention 1 Smelter Government',
  },
},
{
  Intro: {
    description: 'Tantalum Intervention 2 Alternative Pathways Introduction',
  },
  Digger: {
    description: 'Tantalum Intervention 2 Alternative Pathways Digger',
  },
  Washer: {
    description: 'Tantalum Intervention 2 Alternative Pathways Washers and Sorters',
  },
  'Small commerce & sexual services': {
    description: 'Tantalum Intervention 2 Alternative Pathways Small Commerce',
  },
  'Armed groups': {
    description: 'Tantalum Intervention 2 Alternative Pathways Armed Groups',
  },
  'Community leaders': {
    description: 'Tantalum Intervention 2 Alternative Pathways Community Leaders',
  },
  'CSOs & child protection workers': {
    description: 'Tantalum Intervention 2 Alternative Pathways CSO and Child Protection',
  },
  Parents: {
    description: 'Tantalum Intervention 2 Alternative Pathways Parents',
  },
  Schools: {
    description: 'Tantalum Intervention 2 Alternative Pathways Schools',
  },
  'Government agencies': {
    description: 'Tantalum Intervention 2 Alternative Pathways Government Agencies',
  },
}];

const SESAME_AFFECTED_BY = [
  ['supplier', 'smallholder', 's-trader', 's-labour', 'coins', 's-notepad', 'transport', 'building', 'randd'],
  ['smallholder', 'supplier', 'transport', 'government'],
  ['smallholder', 'coops', 'industrial-farm', 's-notepad', 's-notepad', 's-hand-star']];

const SESAME_INTERVENTION_DESCRIPTIONS:
[interventionDescriptions, interventionDescriptions, interventionDescriptions] = [
  {
    Intro: {
      description: 'Sesame Intervention 1 Business Clustering Introduction',
    },
    'Smallholder Farmers': {
      description: 'Sesame Intervention 1 Business Clustering Smallholder Farmers',
    },
    'Seed Supplier': {
      description: 'Sesame Intervention 1 Business Clustering Seed and Fertiliser Supply',
    },
    Trader: {
      description: 'Sesame Intervention 1 Business Clustering Traders and Aggregators',
    },
    'Labour recruitment': {
      description: 'Sesame Intervention 1 Business Clustering Labour Recruitment',
    },
    'Banks/ Finance': {
      description: 'Sesame Intervention 1 Business Clustering Finance',
    },
    Schools: {
      description: 'Sesame Intervention 1 Business Clustering Schools',
    },
    Transport: {
      description: 'Sesame Intervention 1 Business Clustering Transport',
    },
    'Government agencies': {
      description: 'Sesame Intervention 1 Business Clustering Government Agencies',
    },
    'R&D': {
      description: 'Sesame Intervention 1 Business Clustering Research & Development',
    },
  },
  {
    Intro: {
      description: 'Sesame Intervention 2 World Class Introduction',
    },
    'Smallholder Farmers': {
      description: 'Sesame Intervention 2 World Class Smallholder Farmers',
    },
    'Seed Supplier': {
      description: 'Sesame Intervention 2 World Class Seed / Fertiliser Suppliers',
    },
    Transport: {
      description: 'Sesame Intervention 2 World Class Transport',
    },
    'Government agencies': {
      description: 'Sesame Intervention 2 World Class Government Agencies',
    },
  },
  {
    Intro: {
      description: 'Sesame Intervention 3 Health Education Introduction',
    },
    'Smallholder Farmers': {
      description: 'Sesame Intervention 3 Health Education Smallholder Farmers',
    },
    'Primary Cooperatives': {
      description: 'Sesame Intervention 3 Health Education Primary Cooperatives',
    },
    'Industrial farms': {
      description: 'Sesame Intervention 3 Health Education Large Scale Producers',
    },
    Schools: {
      description: 'Sesame Intervention 3 Health Education Schools',
    },
    Parents: {
      description: 'Sesame Intervention 3 Health Education Parents',
    },
    'Community leaders': {
      description: 'Sesame Intervention 3 Health Education Community Leaders',
    },
  },
];

const LocalLevelDiagram = ({ getText }: { getText: textContentFunction }) => {
  const [intervention, setIntervention] = useState(0);
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState('');
  const [activeName, setActiveName] = useState('');
  const [description, setDescription] = useState('Tantalum Local Introduction');

  const [ref, isRevealed] = useIsRevealed({ threshold: 1 });

  const { industry } = useContext(IndustryContext);

  const intro = industry === 'mining' ? 'Tantalum Local Introduction' : 'Sesame Local Introduction';

  useEffect(() => {
    setIntervention(0);
    setActiveName('');
    setContent('');
    setDescription(intro);
  }, [industry]);

  const affectedByIntervention = (industry === 'mining') ? AFFECTED_BY : SESAME_AFFECTED_BY;

  const isActiveAffected = intervention > 0
    && affectedByIntervention[intervention - 1].includes(activeName);

  const interventionText = (industry === 'mining') ? INTERVENTION_TEXT : SESAME_INTERVENTION_TEXT;
  const interventionDescriptions = (industry === 'mining') ? INTERVENTION_DESCRIPTIONS : SESAME_INTERVENTION_DESCRIPTIONS;

  const setActiveElement = (newContent : string, name : string, newDescription : string) => {
    setContent(newContent);
    setActiveName(name);
    setDescription(newDescription);
  };

  const updateIntervention = (newIntervention: number) => {
    setIntervention(newIntervention);
    setActiveName('');
    setContent('');
    if (newIntervention === 0) {
      setDescription(intro);
    } else {
      setDescription(interventionDescriptions[newIntervention - 1].Intro.description);
    }
  };

  const InterventionSelector = () => (
    <div className={`intervention-selector${intervention > 0 ? ' is-intervention-selected' : ''}`}>
      <div className="intervention-selector-inner">
        <button type="button" onClick={() => setVisible(true)}>
          <InterventionIndicator
            label={intervention === 0 ? 'i' : `${intervention}`}
            active={intervention > 0}
          />
        </button>
        <div className="intervention-selector-cta" aria-live="polite">
          { intervention > 0 ? `Intervention ${intervention} added`
            : 'Try adding an intervention' }
        </div>
        <div className={`intervention-selector-remove${
          intervention > 0 ? ' is-visible' : ''}`}
        >
          <CloseButton onClick={() => updateIntervention(0)} label="Remove" />
        </div>
        <div className={`intervention-selector-modal${visible ? ' is-visible' : ''}`}>
          <div className="intervention-selector-modal-header">
            <span>Add an intervention</span>
            <CloseButton onClick={() => setVisible(false)} />
          </div>
          {interventionText.map((text, interventionNumber) => (
            <div
              className="intervention-selector-modal-content"
              key={`int${interventionNumber + 1}`}
            >
              <button
                type="button"
                onClick={() => updateIntervention(interventionNumber + 1)}
                aria-label={`Select intervention number ${interventionNumber + 1}`}
              >
                <InterventionIndicator
                  label={`${interventionNumber + 1}`}
                  active={intervention === interventionNumber + 1}
                  aria-hidden
                />
              </button>
              <div
                className={
                  intervention === interventionNumber + 1 ? 'highlighted-text' : ''
                }
              >
                {text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={
        `local-level-diagram${isActiveAffected ? ' is-active-affected' : ''}${
          isRevealed ? ' is-revealed' : ''}`
      }
      ref={ref}
    >
      <div className="local-level-diagram-text" aria-live="polite">
        <p>
          {intervention > 0 && content in interventionDescriptions[intervention - 1]
            ? getText(interventionDescriptions[intervention - 1][content].description)
            : getText(description) }
        </p>
      </div>
      <div
        onClick={() => setVisible(false)}
        onKeyPress={() => setVisible(false)}
        role="button"
        aria-label="Close modal if open"
        tabIndex={0}
        className="local-level-diagram-diagram"
      >
        <div className="local-level-diagram-holder">
          <div className="lld-circular-diagram">
            <CircularDiagram />
          </div>
          {EnvironmentSign}
          {MainCircle}
          <div className="local-level-diagrams-labels">
            <div className="local-level-diagrams-labels-top">
              <div className="lld-labels-origin">Origin</div>
              <div className="lld-labels-place">
                {industry === 'mining' ? 'Masisi Mine' : 'Metema Woreda Farm'}

              </div>
              <div className="lld-labels-country">
                {industry === 'mining' ? 'Democratic Republic of Congo' : 'Ethiopia'}
              </div>
            </div>
            <div className="local-level-diagrams-labels-middle">
              <div className={`local-level-diagrams-labels-middle-inner industry-${industry}`}>
                <div>
                  {industry === 'mining' ? 'Mining' : 'Farming'}
                  {' '}
                  raw materials
                </div>
                <div>Trading</div>
                <div>Processing</div>
                <div>Leaving the country</div>
              </div>
            </div>
          </div>
          {[['bottom', industry === 'mining' ? BOTTOM_ICONS : SESAME_BOTTOM_ICONS],
            ['outer', industry === 'mining' ? OUTER_ICONS : SESAME_OUTER_ICONS],
            ['inner', industry === 'mining' ? INNER_ICONS : SESAME_INNER_ICONS]].map(
            ([type, icons] : [string, IconSet[]]) => (
              <div className={`lld-${type}-icons`}>
                {icons.map(([icon, name, label, newDescription]) => (
                  <button
                    type="button"
                    key={name}
                    className={`lld-button lld-icon lld-icon-${name}${
                      content === label ? ' is-active' : ''}${
                      intervention > 0
                    && affectedByIntervention[intervention - 1].includes(name)
                        ? ' is-affected' : ''
                    }`}
                    aria-label={label}
                    onClick={() => setActiveElement(label, name, newDescription)}
                  >
                    {icon}
                  </button>
                ))}
              </div>
            ),
          )}
          {industry === 'mining' ? GroupingElementMining : GroupingElementFarming}
        </div>
      </div>
      <InterventionSelector />
    </div>
  );
};

export default LocalLevelDiagram;
