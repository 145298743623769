import React from 'react';

const EnvironmentSign = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 189.9 27.8"
    className="lld-env-sign"
  >
    <path d="M3.5 27.6 0 9.8l11-2.2.6 2.5-8.4 1.7 1 4.7 6.5-1.3.5 2.5L4.7 19l1 5.6 8.6-1.7.5 2.5Z" />
    <path d="M30.3 22.9 19.2 10.7l2 13.6-2.6.4-2.8-18 2.8-.4 10.5 11.5L27 5l2.7-.4 2.8 18Z" />
    <path d="m45.4 21-3.1.4L33.5 4l2.9-.3 7 14.1 3.8-15.3 2.9-.3Z" />
    <path d="m53.5 2 2.8-.3L57.7 20 55 20z" />
    <path d="m73 19-4.4-6.8-3.6.2.4 7h-2.9l-1-18 6.2-.4c3.7-.2 6.3 1 6.5 5a5.6 5.6 0 0 1-3 5.3l4.7 7.5ZM64.5 3.3l.3 6.3 3.5-.2c1.9 0 3.2-1.1 3-3.3 0-2-1.2-3-3.8-3Z" />
    <path d="M78 9.6C78 3.8 81.7 0 87 0s9 3.6 9 9.4-3.6 9.5-8.8 9.5-9-3.6-9-9.3m3 0c0 3.8 2 6.8 6 6.8s5.9-3.1 5.8-7-2-6.9-6-6.8-6 3-5.9 7" />
    <path d="m111.8 19-8.7-14-.4 13.6h-2.8l.6-18.3 3 .1 8 13.3.5-13 2.7.2-.6 18.1Z" />
    <path d="m134 20.5-.6-13.3-5.6 8.7-4.1-9.5-2.8 13-2.8-.2 3.9-18 2.3.3 4 9 5.3-8.3 2.3.2 1 18.4Z" />
    <path d="m140.7 21.2 2.3-18 11.2 1.4-.3 2.5-8.5-1-.6 4.8 6.7.8-.3 2.6-6.7-.9-.7 5.6 8.7 1.1-.3 2.6Z" />
    <path d="m167.7 25.2-6.8-15-2.3 13.5-2.7-.5 3-18 2.9.5L168 20l2.1-12.8 2.7.5-3 18Z" />
    <path d="m184.2 12.5-3.1 15.3-2.8-.6 3.2-15.3-5.1-1 .5-2.6 13 2.7-.5 2.6Z" />
  </svg>
);

export default EnvironmentSign;
