import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import TitleVector from './title-vector';

import './hero.scss';

const HeroComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`hero-component${isVisible ? ' is-visible' : ''}`}>
      <div className="hero-component-image">
        <StaticImage
          src="./hero.jpg"
          alt="Children working in a mine"
          quality={95}
        />
        <div className="hero-component-title">
          {TitleVector}
        </div>
      </div>
      <div className="hero-component-cta">
        <p>
          How can we more effectively prevent and reduce the worst forms of
          child labour in global supply chains?
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32.5 32.5"
          className="hero-component-cta-arrow"
        >
          <path d="M30 14 16 28 2 14" />
        </svg>
      </div>
    </div>
  );
};

export default HeroComponent;
