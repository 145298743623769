const data: { [key: string]: number } = {
  Afghanistan: 5.3,
  Albania: 1.3,
  Algeria: 8,
  'American Samoa': 9.1,
  Andorra: 1.3,
  Angola: 19,
  Anguilla: 11.4,
  'Antigua and Barbuda': 11.4,
  Argentina: 3.5,
  Armenia: 6.8,
  Aruba: 11.4,
  Australia: 9.1,
  Austria: 0.2,
  Azerbaijan: 6.8,
  'The Bahamas': 11.4,
  Bahrain: 5.7,
  Bangladesh: 5.3,
  Barbados: 11.4,
  Belarus: 5,
  Belgium: 0.2,
  Belize: 6.9,
  Benin: 22.8,
  Bermuda: 0.2,
  Bhutan: 5.3,
  Bolivia: 3.5,
  'Bosnia and Herzegovina': 1.3,
  Botswana: 3.3,
  Brazil: 3.5,
  'British Virgin Islands': 11.4,
  Brunei: 11.2,
  Bulgaria: 5,
  'Burkina Faso': 22.8,
  Burundi: 30.8,
  Cambodia: 11.2,
  Cameroon: 19,
  Canada: 0.2,
  'Cape Verde': 22.8,
  'Cayman Islands': 11.4,
  'Central African Republic': 19,
  Chad: 19,
  'Channel Islands': 0.2,
  Chile: 3.5,
  China: 3.5,
  Colombia: 3.5,
  Comoros: 30.8,
  'Republic of the Congo': 19,
  'Democratic Republic of the Congo': 19,
  'Cook Islands': 9.1,
  'Costa Rica': 6.9,
  Croatia: 1.3,
  Cuba: 11.4,
  Curaçao: 11.4,
  Cyprus: 6.8,
  'Northern Cyprus': 6.8,
  'Czech Republic': 5,
  'Ivory Coast': 22.8,
  Denmark: 0.2,
  Djibouti: 30.8,
  Dominica: 11.4,
  'Dominican Republic': 11.4,
  Ecuador: 3.5,
  Egypt: 8,
  'El Salvador': 6.9,
  'Equatorial Guinea': 19,
  Eritrea: 30.8,
  Estonia: 0.2,
  Ethiopia: 30.8,
  'Faeroe Islands': 0.2,
  'Falkland Islands': 3.5,
  Fiji: 9.1,
  Finland: 0.2,
  France: 0.2,
  'French Guiana': 3.5,
  'French Polynesia': 9.1,
  Gabon: 19,
  Gambia: 22.8,
  Georgia: 6.8,
  Germany: 0.2,
  Ghana: 22.8,
  Gibraltar: 1.3,
  Greece: 1.3,
  Greenland: 0.2,
  Grenada: 11.4,
  Guadeloupe: 11.4,
  Guam: 9.1,
  Guatemala: 6.9,
  Guernsey: 0.2,
  Guinea: 22.8,
  'Guinea Bissau': 22.8,
  Guyana: 3.5,
  Haiti: 11.4,
  Honduras: 6.9,
  'Hong Kong, China': 3.5,
  Hungary: 5,
  Iceland: 0.2,
  India: 5.3,
  Indonesia: 11.2,
  Iran: 5.3,
  Iraq: 5.7,
  Ireland: 0.2,
  'Isle of Man': 0.2,
  Israel: 6.8,
  Italy: 1.3,
  Jamaica: 11.4,
  Japan: 3.5,
  Jersey: 0.2,
  Jordan: 5.7,
  Kazakhstan: 12.4,
  Kenya: 30.8,
  Kiribati: 9.1,
  'North Korea': 3.5,
  'South Korea': 3.5,
  Kosovo: 1.3,
  Kuwait: 5.7,
  Kyrgyzstan: 12.4,
  Laos: 11.2,
  Latvia: 0.2,
  Lebanon: 5.7,
  Lesotho: 3.3,
  Liberia: 22.8,
  Libya: 8,
  Liechtenstein: 0.2,
  Lithuania: 0.2,
  Luxembourg: 0.2,
  'Macau, China': 3.5,
  Macedonia: 1.3,
  Madagascar: 30.8,
  Malawi: 30.8,
  Malaysia: 11.2,
  Maldives: 5.3,
  Mali: 22.8,
  Malta: 1.3,
  'Marshall Islands': 9.1,
  Martinique: 11.4,
  Mauritania: 22.8,
  Mauritius: 30.8,
  Mayotte: 30.8,
  Mexico: 6.9,
  'Micronesia, Federated States of': 9.1,
  Moldova: 5,
  Monaco: 0.2,
  Mongolia: 3.5,
  Montenegro: 1.3,
  Montserrat: 11.4,
  Morocco: 8,
  Mozambique: 30.8,
  Myanmar: 11.2,
  Namibia: 3.3,
  Nauru: 9.1,
  Nepal: 5.3,
  Netherlands: 0.2,
  'Netherlands Antilles': 11.4,
  'New Caledonia': 9.1,
  'New Zealand': 9.1,
  Nicaragua: 6.9,
  Niger: 22.8,
  Nigeria: 22.8,
  Niue: 9.1,
  'Norfolk Island': 9.1,
  'Northern Mariana Islands': 9.1,
  Norway: 0.2,
  'Occupied Palestinian Territory': 5.7,
  Oman: 5.7,
  Pakistan: 5.3,
  Palau: 9.1,
  Panama: 6.9,
  'Papua New Guinea': 9.1,
  Paraguay: 3.5,
  Peru: 3.5,
  Philippines: 11.2,
  Poland: 5,
  Portugal: 1.3,
  'Puerto Rico': 11.4,
  Qatar: 5.7,
  Romania: 5,
  Russia: 5,
  Rwanda: 30.8,
  Réunion: 30.8,
  'Saint Helena': 22.8,
  'Saint Kitts and Nevis': 11.4,
  'Saint Lucia': 11.4,
  'Saint Martin (French part)': 11.4,
  'Saint Pierre and Miquelon': 0.2,
  'Saint Vincent and the Grenadines': 11.4,
  Samoa: 9.1,
  'San Marino': 1.3,
  'Sao Tome and Principe': 19,
  'Saudi Arabia': 5.7,
  Senegal: 22.8,
  'Republic of Serbia': 1.3,
  Seychelles: 30.8,
  'Sierra Leone': 22.8,
  Singapore: 11.2,
  'Sint Maarten (Kingdom of the Netherlands)': 11.4,
  Slovakia: 5,
  Slovenia: 1.3,
  'Solomon Islands': 9.1,
  Somalia: 30.8,
  Somaliland: 30.8,
  'South Africa': 3.3,
  'South Sudan': 30.8,
  Spain: 1.3,
  'Sri Lanka': 5.3,
  Sudan: 8,
  Suriname: 3.5,
  Swaziland: 3.3,
  Sweden: 0.2,
  Switzerland: 0.2,
  Syria: 5.7,
  Taiwan: 3.5,
  Tajikistan: 12.4,
  'United Republic of Tanzania': 30.8,
  Thailand: 11.2,
  'East Timor': 11.2,
  Togo: 22.8,
  Tokelau: 9.1,
  Tonga: 9.1,
  'Trinidad and Tobago': 11.4,
  Tunisia: 8,
  Turkey: 6.8,
  Turkmenistan: 12.4,
  'Turks and Caicos Islands': 11.4,
  Tuvalu: 9.1,
  Uganda: 30.8,
  Ukraine: 5,
  'United Arab Emirates': 5.7,
  England: 0.2,
  USA: 0.2,
  'United States Virgin Islands': 11.4,
  Uruguay: 3.5,
  Uzbekistan: 12.4,
  Vanuatu: 9.1,
  Venezuela: 3.5,
  Vietnam: 11.2,
  'Wallis and Futuna Islands': 9.1,
  'Western Sahara': 8,
  'West Bank': 5.7,
  Yemen: 5.7,
  Zambia: 30.8,
  Zimbabwe: 30.8,
  'French Southern and Antarctic Lands': 0,
};

export default data;
