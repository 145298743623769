import React from 'react';

import './close-button.scss';

interface CloseButtonProps {
  onClick: any
  label?: string
}

const CloseButton = ({ onClick, label }: CloseButtonProps) => (
  <button type="button" className="lld-close-button" onClick={onClick}>
    <svg
      viewBox="0 0 8.4 8.4"
      xmlns="http://www.w3.org/2000/svg"
      className="lld-close-button-svg"
    >
      <g transform="translate(-115.8 -68) scale(.26458)">
        <circle className="st0" cx="453.5" cy="272.9" r="13.9" />
        <path className="st0" d="m447.7 267.1 11.6 11.6M459.3 267.1l-11.6 11.6" />
      </g>
    </svg>
    { label ? <span>{label}</span> : ''}
  </button>
);

CloseButton.defaultProps = {
  label: null,
};

export default CloseButton;
