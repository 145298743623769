import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { LogoPACE } from './logos';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer-inner">
      <div className="footer-columns">
        <div className="footer-main-logos">
          <div className="footer-logo-pace">
            <a href="https://www.pace-consortium.org/" target="_blank" rel="noreferrer">
              { LogoPACE }
            </a>
          </div>
          <div className="footer-uk-aid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.2 39.9">
              <g fill="#fff">
                <path d="M31.7 39.9v-2.5h.2v.1a.8.8 0 0 1 1-.2.8.8 0 0 1 .2.1 1.1 1.1 0 0 1 0 1.6.9.9 0 0 1-1.2 0v.9Zm.2-1.7c0 .4.2.7.5.8a.6.6 0 0 0 .7-.6c0-.4-.1-.8-.5-.9-.4 0-.7.3-.7.7" />
                <path d="M25.6 39.9v-2.5h.2v.1a.8.8 0 0 1 1-.2 1 1 0 0 1 .5 1.3.8.8 0 0 1-1 .6 2.3 2.3 0 0 1-.4-.2v.9Zm1.4-1.6V38a.6.6 0 0 0-.5-.5.5.5 0 0 0-.6.5 1.5 1.5 0 0 0 0 .6.5.5 0 0 0 .6.4.5.5 0 0 0 .5-.4 2 2 0 0 0 0-.3" />
                <path d="M0 37.3h.3v-.4a.5.5 0 0 1 .7-.3v.2c-.4 0-.4 0-.3.5H1v.2H.6v1.6c-.2 0-.3 0-.3-.2v-1.3H0Z" />
                <path d="M20.3 8.7h16V13h-16v8.6H16V13H0V8.7h15.7c.3 0 .3 0 .3-.3V0H20c.2 0 .2 0 .2.2v8.2Z" />
                <path d="M34.1 27.4v-3.7h2v10.7h-1.9v-1l-.4.5a2.3 2.3 0 0 1-1.8.8 3.1 3.1 0 0 1-3.2-2.2 5.6 5.6 0 0 1 .2-4.3 3 3 0 0 1 3.2-1.9 2.4 2.4 0 0 1 1.7 1l.2.2m-3.5 3a3.8 3.8 0 0 0 .3 1.6 1.5 1.5 0 0 0 1.3.9 1.6 1.6 0 0 0 1.6-.8 2.8 2.8 0 0 0 .4-1.3 3.2 3.2 0 0 0-.5-2 1.6 1.6 0 0 0-1.6-.8 1.5 1.5 0 0 0-1.2 1 3.4 3.4 0 0 0-.3 1.3" />
                <path d="M16.7 29a2.5 2.5 0 0 1 1.8-2.4 5.4 5.4 0 0 1 3.9.2 1.9 1.9 0 0 1 1 1.9v4a4.8 4.8 0 0 0 .1.9.8.8 0 0 0 .2.4.3.3 0 0 1 .2.4h-2.3l-.1-.8a9.2 9.2 0 0 1-1 .7 3 3 0 0 1-3 0 2 2 0 0 1-1-1.6 2.6 2.6 0 0 1 .4-2 2.3 2.3 0 0 1 1.4-.8l2-.3a4.5 4.5 0 0 0 .6-.1.6.6 0 0 0 .5-.7.7.7 0 0 0-.4-.7 2.4 2.4 0 0 0-1.6 0 1 1 0 0 0-.7.8c0 .1 0 .2-.2.2h-1.8m4.7 1.5-1.1.4-1.2.3a1 1 0 0 0-.6 1 .9.9 0 0 0 .8.9 2 2 0 0 0 .7 0 1.6 1.6 0 0 0 1.2-1 4.1 4.1 0 0 0 .2-1.6" />
                <path d="M10 29.7a1 1 0 0 1 0-.2l3-3a.4.4 0 0 1 .3-.2h2l-3.4 3.3 3.5 4.8h-2a.3.3 0 0 1-.2-.1L10.9 31l-.1-.2-.9.8v2.7H8.3v-8h1.6v3.2h.1" />
                <path d="M4.3 0h9.9c.3 0 .3 0 .3.3V6L4.3.1" />
                <path d="M21.8 6V0h10.1L21.8 6" />
                <path d="m4.3 21.6 10.2-6v6Z" />
                <path d="M21.8 21.6v-6l10.1 6Z" />
                <path d="M0 26.3h1.7V31.2a5.6 5.6 0 0 0 .2 1 1.1 1.1 0 0 0 .9 1 2.3 2.3 0 0 0 1.1 0A1.2 1.2 0 0 0 5 32V26.3h1.7v4.9a7.3 7.3 0 0 1-.2 1.3 2.3 2.3 0 0 1-1.7 2 4.8 4.8 0 0 1-2.9 0 2.4 2.4 0 0 1-1.7-2 6.8 6.8 0 0 1 0-1.2v-5" />
                <path d="m21.8 7.2.1-.3L33.3 0a.5.5 0 0 1 .2 0h2.7l-.5.2-11.4 6.8a.5.5 0 0 1-.3 0H22a.7.7 0 0 1-.1 0" />
                <path d="m.1 21.6 2.1-1.3 9.7-5.8a.8.8 0 0 1 .4 0h2.2l-.1.2L3 21.5a.4.4 0 0 1-.2.1H0" />
                <path d="M36.2 2.5V7c0 .1 0 .2-.2.2h-7.5l7.7-4.7" />
                <path d="M7.8 7.2H0V2.6l7.7 4.6" />
                <path d="M36.2 14.4v4.7l-7.7-4.6Z" />
                <path d="M0 19v-4.6h7.8L0 19.1" />
                <path d="m0 0 12 7.2H9.4a.6.6 0 0 1-.2 0l-9-5.4a.3.3 0 0 1-.2-.3V0" />
                <path d="M24.3 14.4h2a2 2 0 0 1 1.2.3l8.5 5.1a.3.3 0 0 1 .2.4v1.4l-12-7.1h.1" />
                <path d="M27.2 34.4h-2v-7.9H27c.2 0 .2 0 .2.3v7.6Z" />
                <path d="M25.1 23.7h2.1v2.1h-2z" />
                <path d="M14.6 36.6H16a.5.5 0 0 1 .5.5.6.6 0 0 1-.3.6v.1a.6.6 0 0 1 .4.7.7.7 0 0 1-.6.6h-1.4Zm.8 2.3h.5a.4.4 0 0 0 .4-.5.4.4 0 0 0-.4-.4 5.4 5.4 0 0 0-.6 0c-.4 0-.4 0-.4.4 0 .5 0 .5.5.5m-.5-1.2h1a.4.4 0 0 0 .3-.4c0-.2 0-.4-.3-.4h-1Z" />
                <path d="M5.9 39.2h-.3v-1.3a.3.3 0 0 0-.2-.4.4.4 0 0 0-.5.1.8.8 0 0 0-.2.4 8.4 8.4 0 0 0 0 1c0 .2 0 .2-.3.1v-1.8h.3v.2a3.3 3.3 0 0 1 .6-.2.5.5 0 0 1 .5.3.8.8 0 0 1 .8-.3.4.4 0 0 1 .4.4v1.5h-.2V37.9c0-.2-.1-.3-.3-.4a.5.5 0 0 0-.6.3 1.5 1.5 0 0 0 0 .4v1" />
                <path d="M29.2 38.6a.7.7 0 0 1-.6.6.8.8 0 0 1-.9-.4 1.1 1.1 0 0 1 0-1 .8.8 0 0 1 .8-.5.8.8 0 0 1 .7.6l.1.4h-1.4a.5.5 0 0 0 .4.6.6.6 0 0 0 .6-.2l.3-.1M28 38h1c0-.3-.2-.5-.5-.5s-.5.2-.5.5" />
                <path d="M36.1 38.3h-1.4a.5.5 0 0 0 .3.6.6.6 0 0 0 .7-.1c.1-.2.1-.2.3-.2a.6.6 0 0 1-.5.6.8.8 0 0 1-1-.4 1.1 1.1 0 0 1 0-1 .8.8 0 0 1 .8-.5.8.8 0 0 1 .8.6v.4m-.3-.2c0-.4-.3-.6-.6-.6a.5.5 0 0 0-.5.6Z" />
                <path d="M22.5 39.1v-2.5h.3v1a.8.8 0 0 1 .8-.3.5.5 0 0 1 .4.4V39c0 .3-.1.1-.2.2V38c0-.3-.1-.5-.4-.5a.5.5 0 0 0-.6.5v1c0 .2 0 .2-.3.1" />
                <path d="M13.2 38.3h-1.4a.5.5 0 0 0 .4.6.6.6 0 0 0 .7-.2l.2-.1a.7.7 0 0 1-.7.6.8.8 0 0 1-.8-.4 1 1 0 0 1 .1-1.2.8.8 0 0 1 1-.3.9.9 0 0 1 .3.4 3 3 0 0 1 .2.6m-1.4-.2h1c0-.4-.2-.6-.5-.6s-.4.2-.5.6" />
                <path d="M11.1 39.2c-.2 0-.2 0-.2-.2v-1c0-.3-.1-.4-.3-.5a.5.5 0 0 0-.6.2 1.1 1.1 0 0 0 0 .5l-.1 1h-.3v-2.6h.3v1a.8.8 0 0 1 .6-.3.5.5 0 0 1 .6.5v1.4" />
                <path d="M31.3 38.2a.9.9 0 0 1-1.1 1 1 1 0 1 1 .6-1.9.9.9 0 0 1 .5 1m-.3 0a.8.8 0 0 0 0-.2.6.6 0 0 0-.4-.6.6.6 0 0 0-.6.2.9.9 0 0 0 0 1 .7.7 0 0 0 .4.3c.4 0 .6-.3.6-.8" />
                <path d="M2.3 38.2a.9.9 0 0 1 .9-1 1 1 0 0 1 0 2 .9.9 0 0 1-1-1m.4 0a1 1 0 0 0 0 .2.6.6 0 0 0 .4.5.5.5 0 0 0 .6 0 .8.8 0 0 0 0-1.2.6.6 0 0 0-.4-.2c-.4 0-.6.3-.6.7" />
                <path d="M20.6 38.6h.3a.6.6 0 0 0 .8.3.3.3 0 0 0 .2-.2.4.4 0 0 0-.2-.3 2 2 0 0 0-.4 0 2.4 2.4 0 0 1-.4-.2.4.4 0 0 1 0-.8 1 1 0 0 1 1 0 .5.5 0 0 1 .2.4l-.3-.1a.6.6 0 0 0-.8 0 .2.2 0 0 0 0 .2 2.1 2.1 0 0 0 .5.2h.4a.4.4 0 0 1 .3.5.5.5 0 0 1-.3.5 1.1 1.1 0 0 1-1 0c-.2 0-.2-.2-.3-.5" />
                <path d="M8.7 37.6h-.3v-.3h.3v-.5h.2v.5h.4v.2H9v1.3l.1.1a.5.5 0 0 0 .2 0c0 .3 0 .3-.2.3-.4 0-.4-.1-.4-.5v-1.1" />
                <path d="M18.7 37.3H19v-.5h.3v.5h.4v.2h-.4v1.3l.2.1a.6.6 0 0 0 .2 0v.3a1.8 1.8 0 0 1-.5 0 .4.4 0 0 1-.1-.4v-1.2h-.4Z" />
                <path d="M33.8 39.2v-2.6h.2V38.9c0 .3 0 .3-.2.3" />
                <path d="M1.2 39.1v-1.8h.3v.3a.8.8 0 0 1 .7-.3v.2H2a.6.6 0 0 0-.5.6v.8c0 .3 0 .3-.3.2" />
                <path d="M17.3 39.2H17v-1.9h.2v.4a.7.7 0 0 1 .7-.4l-.1.3a.5.5 0 0 0-.5.5v1" />
                <path d="M20 37.3h.3v1.8H20z" />
                <path d="M18.2 37.3h.3V39c0 .3 0 .3-.3.3Z" />
                <path d="M20 36.6h.3v.3H20z" />
                <path d="M18.2 36.6h.2c.1.3 0 .4-.2.3Z" />
              </g>
            </svg>
            This material has been funded by UK aid from the UK government.
            However, the views expressed do not necessarily reflect the UK
            government’s official policies.
          </div>
        </div>
        <div className="footer-partners">
          <div className="footer-partners-logos">
            <div>
              <a href="https://www.cpcnetwork.org/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="./logos/cpc.png"
                  alt="Care and Protection of Children (CPC) Learning Network"
                  style={{ width: '90px' }}
                  width={90}
                  backgroundColor="#000000"
                />
              </a>
            </div>
            <a href="https://www.50eight.com/" target="_blank" rel="noreferrer">
              <StaticImage
                src="./fiftyeight-logo-white.png"
                alt="Fifty Eight logo"
                style={{ width: '150px' }}
                width={150}
                backgroundColor="#000000"
              />
            </a>
            <div>
              <a href="https://www.trust.org/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="./logos/thomson-reuters.png"
                  alt="Thomson Reuters Foundation"
                  style={{ width: '90px' }}
                  width={90}
                  backgroundColor="#000000"
                />
              </a>
            </div>
            <div>
              <a href="https://www.unglobalcompact.org.uk/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="./logos/un.png"
                  alt="UN Global Compact Network UK"
                  style={{ width: '90px' }}
                  width={90}
                  backgroundColor="#000000"
                />
              </a>
            </div>
            <div>
              <a href="https://www.worldvision.org.uk/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="./logos/world-vision.png"
                  alt="World Vision UK"
                  style={{ width: '150px' }}
                  width={150}
                  backgroundColor="#000000"
                />
              </a>
            </div>
            <div>
              <a href="https://www.warchild.org.uk/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="./logos/war-child.png"
                  alt="War Child UK"
                  style={{ width: '90px' }}
                  width={90}
                  backgroundColor="#000000"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-links">
          <ul className="footer-links-list">
            <li>
              <a href="https://www.pace-consortium.org/about">About PACE</a>
            </li>
            <li>
              <a href="https://www.pace-consortium.org/contact-us">Contact us</a>
            </li>
            <li>
              <a href="https://www.pace-consortium.org/terms-conditions">Terms and conditions</a>
            </li>
            <li>
              <a href="https://www.pace-consortium.org/privacy-policy">Privacy policy</a>
            </li>
            <li>
              <a href="https://www.pace-consortium.org/cookie-policy">Cookie Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        <div>
          Developed by
          {' '}
          <a href="https://www.50eight.com/" target="_blank" rel="noreferrer">
            Fifty Eight

          </a>
          {' '}
          in collaboration with
          {' '}
          <a href="https://noble.studio" target="_blank" rel="noreferrer">
            Noble&nbsp;Studio

          </a>
          {' '}
          and
          {' '}
          <a href="https://goodpraxis.coop" target="_blank" rel="noreferrer">
            Good&nbsp;Praxis

          </a>
          .
        </div>
        <div>Copyright © 2022 PACE Consortium. All Rights Reserved.</div>
      </div>
    </div>
  </footer>
);

export default Footer;
