import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

import './map.scss';

const GEO_URL = './world.geojson';

const CLASS_HIGHLIGHTED = 'highlighted';

const MAP_WIDTH = 600;
const MAP_HEIGHT = 400;

interface CountryNode {
  properties: {
    name: string
  }
}

export type MapHighlightColor = 'red' | 'green' | 'purple';

const createMap = async (svg: any, countries: string[]) => {
  const projection = d3.geoNaturalEarth1()
    .scale(MAP_WIDTH / 1.6 / Math.PI)
    .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2]);

  const data = await d3.json(GEO_URL) as {features: any};

  svg.append('g')
    .selectAll('path')
    .data(data.features)
    .join('path')
    .attr('fill', 'currentColor')
    .attr('d', d3.geoPath().projection(projection))
    .attr('name', ({ properties: { name } }: CountryNode) => name)
    .attr('class', ({ properties: { name } }: CountryNode) => (
      countries.includes(name) ? CLASS_HIGHLIGHTED : null))
    .style('stroke', '#FAF6EF');
};

const highlightCountries = (svg : SVGSVGElement, countries: string[]) => {
  svg.querySelectorAll('path').forEach((path) => {
    path.classList.toggle(
      CLASS_HIGHLIGHTED, countries.includes(path.getAttribute('name')) || countries.length === 0,
    );
  });
};

interface MapDiagramProps {
  countries: string[]
  color?: MapHighlightColor
}

const MapDiagram = ({ countries, color }: MapDiagramProps) => {
  const ref = useRef(null as SVGSVGElement);

  useEffect(() => {
    createMap(d3.select(ref.current), countries);

    return () => {
      ref.current.innerHTML = '';
    };
  }, []);

  useEffect(() => {
    highlightCountries(ref.current, countries);
  }, [countries]);

  return (
    <svg
      className={`map-diagram use-color-${color}`}
      preserveAspectRatio="xMinYMin meet"
      ref={ref}
      viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}
    />
  );
};

MapDiagram.defaultProps = {
  color: 'red',
};

export default MapDiagram;
