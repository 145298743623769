import React from 'react';

import './grouping-element.scss';

export const GroupingElementMining = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 652.5 53.4"
    className="grouping-element"
  >
    <g>
      <path d="M587.6 21.2h-523" />
      <path d="M64.7 40.3V52H0" />
      <path d="M310.8 40.3V52h-64.6" />
      <path d="M310.8 40.3V52h64.7" />
      <path d="M451 40.3V52h-34.7" />
      <path d="M451 40.3V52h34.6" />
      <path d="M587.8 40.3V52h-64.6" />
      <path d="M587.8 40.3V52h64.7" />
      <path d="M64.7 52h150.7" />
    </g>
    <path d="M64.9 39A18.2 18.2 0 1 1 83 20.6 18.2 18.2 0 0 1 64.9 39" />
    <path d="M64.9 5a15.7 15.7 0 1 1-15.7 15.7A15.7 15.7 0 0 1 64.9 5m0-5a20.7 20.7 0 1 0 20.7 20.7A20.7 20.7 0 0 0 64.9 0" />
    <path d="M310.8 39A18.2 18.2 0 1 1 329 20.6 18.2 18.2 0 0 1 310.8 39" />
    <path d="M310.8 5a15.7 15.7 0 1 1-15.7 15.7A15.7 15.7 0 0 1 310.8 5m0-5a20.7 20.7 0 1 0 20.7 20.7A20.7 20.7 0 0 0 310.8 0" />
    <path d="M451 39A18.2 18.2 0 1 1 469 20.6 18.2 18.2 0 0 1 451 39" />
    <path d="M451 5a15.7 15.7 0 1 1-15.8 15.7A15.7 15.7 0 0 1 451 5m0-5a20.7 20.7 0 1 0 20.7 20.7A20.7 20.7 0 0 0 451 0" />
    <path d="M587.8 39A18.2 18.2 0 1 1 606 20.6 18.2 18.2 0 0 1 587.8 39" />
    <path d="M587.8 5a15.7 15.7 0 1 1-15.7 15.7A15.7 15.7 0 0 1 587.8 5m0-5a20.7 20.7 0 1 0 20.7 20.7A20.7 20.7 0 0 0 587.8 0" />
  </svg>
);

export const GroupingElementFarming = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 655.3 53.5"
    className="grouping-element-farming"
  >
    <path d="M605 21.2H68.6" className="st9" />
    <g transform="translate(-318 -588.2)">
      <circle cx="386.9" cy="608.9" r="18.2" className="st8" />
      <path d="M386.9 593.2a15.7 15.7 0 1 1 0 31.4 15.7 15.7 0 1 1 0-31.4m0-5a20.7 20.7 0 0 0 0 41.4 20.7 20.7 0 0 0 0-41.4z" className="st0" />
    </g>
    <path d="M522.9 38.9c-10 0-18.2-8.2-18.2-18.2s8.2-18.2 18.2-18.2 18.2 8.2 18.2 18.2-8.1 18.2-18.2 18.2z" className="st8" />
    <path d="M522.9 5a15.7 15.7 0 1 1 0 31.4 15.7 15.7 0 1 1 0-31.4m0-5a20.7 20.7 0 0 0 0 41.4 20.7 20.7 0 0 0 0-41.4z" className="st0" />
    <path d="M68.6 40.3V52H0m522.9-11.7V52h-34.6m34.6-11.7V52h34.7" className="st9" />
    <path d="M620.7 38.9c-10 0-18.2-8.2-18.2-18.2s8.2-18.2 18.2-18.2 18.2 8.2 18.2 18.2-8.2 18.2-18.2 18.2z" className="st8" />
    <path d="M620.7 5a15.7 15.7 0 1 1 0 31.4 15.7 15.7 0 1 1 0-31.4m0-5a20.7 20.7 0 0 0 0 41.4 20.7 20.7 0 0 0 0-41.4z" className="st0" />
    <path d="M620.7 40.3V52H586m34.7-11.7V52h34.6M68.6 52h150.7" className="st9" />
    <g transform="translate(-318 -588.2)">
      <circle cx="633.5" cy="608.9" r="18.2" className="st8" />
      <path d="M633.5 593.2a15.7 15.7 0 1 1 0 31.4 15.7 15.7 0 1 1 0-31.4m0-5a20.7 20.7 0 0 0 0 41.4 20.7 20.7 0 0 0 0-41.4z" className="st0" />
    </g>
    <path d="M315.3 40.3V52h-70.7m70.7 0h146.6" className="st9" />
  </svg>
);

export default { GroupingElementMining, GroupingElementFarming };
