import React from 'react';
import { MapHighlightColor } from '../map';

import './circular-diagram.scss';

interface CircularDiagramProps {
  highlightColor?: MapHighlightColor
}

const CircularDiagram = ({ highlightColor }: CircularDiagramProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 469.1 469.1"
    className={`circular-diagram${highlightColor ? ` using-color-${highlightColor}` : ''}`}
  >
    <g className="circular-diagram-circle-outer">
      <path d="M130.8 374.3a176.5 176.5 0 0 1-19.3-16.7 172.9 172.9 0 0 1-51-123 174.6 174.6 0 0 1 7.6-51l-57.6-18.7a234.4 234.4 0 0 0 58.2 235.5 236.1 236.1 0 0 0 26.5 22.9Z" fill="#a35b61" />
      <path d="M31 245.7c4.1-.3 7 2.2 7.3 6a9 9 0 0 1-.5 3.6h-2a9.3 9.3 0 0 0 .4-3.5c-.2-2.7-2.3-3.9-5-3.7s-4.5 1.7-4.3 4.4a9 9 0 0 0 1 3.3l-2 .4a8.7 8.7 0 0 1-1-3.6c-.3-3.7 2.1-6.6 6.2-6.9" fill="#faf6ef" />
      <path d="M32 257.5c4-.5 7 1.7 7.5 5.4s-1.7 6.7-5.7 7.3-7-1.8-7.6-5.4 1.7-6.7 5.8-7.3m.3 2.4c-2.6.4-4.4 2-4 4.6s2.5 3.7 5.1 3.3 4.5-2 4.1-4.6-2.5-3.7-5.2-3.3" fill="#faf6ef" />
      <path d="m29.8 282.6 7.8-8-9.1 2-.5-2.2 12.7-2.7.4 2.1-7.3 7.5 8.5-1.8.5 2.1-12.6 2.8Z" fill="#faf6ef" />
      <path d="m31.6 287.5 2.5-.4a6.2 6.2 0 0 0-.2 4.2c.4 1.4 1.1 2.4 2.2 2 1.3-.3 1.4-1.8 1.5-3.6 0-2 .2-4.1 2.3-4.8 1.7-.4 4 .1 5 3.5a9 9 0 0 1 .2 4.2l-2.2.4a8 8 0 0 0 0-4c-.2-1-1-2.3-2-2-1.1.3-1.2 1.6-1.3 3.1 0 2.2 0 4.8-2.7 5.6-1.4.4-3.8.4-5-3.9a9 9 0 0 1-.3-4.3" fill="#faf6ef" />
      <path d="m39.6 297.6 7-2.5.7 2-6.2 2.3c-2.3.8-4.1 2-3.2 4.5s3 2.3 5.3 1.5l6.2-2.2.8 2-7 2.5c-3.3 1.2-6 .3-7.2-3.1s.3-5.8 3.6-7" fill="#faf6ef" />
      <path d="m43.7 323 7.8-4.6-7-.6 4.3-5.6-8.7 2.5-1-2 12.6-3.6.7 1.6-4.2 5.4 6.8.5.8 1.7-11.1 6.8Z" fill="#faf6ef" />
      <path d="m46.2 328.1 11.5-6 3.7 7.2-1.8 1-2.7-5.3-2.8 1.5 2.2 4-1.9 1-2.1-4L49 329l2.8 5.4-1.8 1Z" fill="#faf6ef" />
      <path d="m55.4 344.5 2.7-4.9-1.2-2.1-4.2 2.5-1.2-1.9 11.1-6.6 2.4 3.9c1.3 2.2 1.5 4.3-1 5.7a4 4 0 0 1-4.3 0l-3.2 5.3Zm6.8-10.2-3.6 2.1 1 1.8c1 1.5 2 1.7 3.2 1 1-.6 1.4-1.5.4-3.2Z" fill="#faf6ef" />
      <path d="M458.6 164.9 401 183.6a174.6 174.6 0 0 1 7.6 51 172.9 172.9 0 0 1-51 123 176.5 176.5 0 0 1-19.3 16.7l35.6 49a236.1 236.1 0 0 0 26.5-22.9A235 235 0 0 0 458.6 165" fill="#a35b61" />
      <path d="m429.9 312.7-12.1-4.6 1.2-3c1-2.9 2.1-4.5 4.8-3.5a3.3 3.3 0 0 1 2.2 2 3 3 0 0 1 3.6-1c3 1 2.8 3.6 2 5.6Zm-9.6-6 3.2 1.3.8-2.2a1.5 1.5 0 0 0-1-2.2c-1.3-.5-1.9.2-2.3 1.3Zm4.8 1.9 3.8 1.4.8-2c.6-1.6.6-2.8-.9-3.3-1.5-.6-2.3.2-2.8 1.5Z" fill="#faf6ef" />
      <path d="M434 301.7c-5.2-6.7-6.5-7.6-7.4-7.9a1.8 1.8 0 0 0-2.4 1.3 3.8 3.8 0 0 0 .9 3.4l-2-.3a4.4 4.4 0 0 1-.6-4.1 3.3 3.3 0 0 1 4.4-2.3c1.6.5 3.4 2 6.2 5.7l1.2-3.9 2 .6Z" fill="#faf6ef" />
      <path d="m437.3 291-12.6-3.2.8-3.2c.8-3 1.7-4.7 4.4-4a3.3 3.3 0 0 1 2.4 1.8 3 3 0 0 1 3.6-1.4c3 .7 3 3.2 2.5 5.3Zm-10.2-4.8 3.2.8.6-2.2a1.5 1.5 0 0 0-1.2-2.1c-1.4-.4-1.8.4-2.1 1.6Zm5 1.2 3.9 1 .5-2c.4-1.7.3-2.9-1.2-3.3-1.6-.4-2.3.6-2.6 1.9Z" fill="#faf6ef" />
      <path d="M69.5 179.3A174.3 174.3 0 0 1 232.3 60.6V0A234.8 234.8 0 0 0 18.4 143.2q-3.6 8.6-6.5 17.4Z" fill="#a35b61" />
      <path fill="#faf6ef" d="m81.2 92 1.5-1.6 9.5 8.7-1.5 1.6z" />
      <path d="m100 91.2-10.9-2.3 6.6 6.6L94 97l-9-9.2 1.5-1.5 10.3 2.2-6.2-6.3 1.6-1.5 9 9.2Z" fill="#faf6ef" />
      <path d="m108 84.1-2 1.7L94 79l1.6-1.5 9.5 5.3-3.9-10.1 1.8-1.5Z" fill="#faf6ef" />
      <path d="M112.6 80.1 104.7 70l6.5-5 1.3 1.7-4.7 3.6 1.8 2.4 3.7-2.8 1.3 1.6-3.7 2.9 2.2 2.8 4.9-3.7 1.2 1.6Z" fill="#faf6ef" />
      <path d="m120.1 73.6-1.2-2.2a6.2 6.2 0 0 0 4.1-1.2c1.2-.8 1.9-1.8 1.2-2.7-.8-1.2-2.2-.7-4-.3-1.9.6-3.9 1.2-5.2-.6-1-1.4-1.2-3.9 1.7-5.9a9 9 0 0 1 3.9-1.5l1.1 2a8 8 0 0 0-3.8 1.2c-.9.6-1.8 1.7-1.2 2.7.6.9 1.9.5 3.3 0 2.1-.6 4.5-1.4 6.2 1 .8 1.1 1.6 3.3-2 5.8a9 9 0 0 1-4.1 1.7" fill="#faf6ef" />
      <path d="m128.2 56 5.7 9.3-1.9 1.2-5.7-9.3-3.1 2-1-1.8 8-5 1.2 1.7Z" fill="#faf6ef" />
      <path d="M134.6 57.3c-2-3.6-.8-7.2 2.4-9s6.9-.6 8.8 3 .8 7.2-2.5 9-6.8.6-8.8-3m2.1-1.1c1.3 2.4 3.4 3.5 5.8 2.2s2.6-3.6 1.3-6-3.4-3.5-5.7-2.2-2.6 3.6-1.4 6" fill="#faf6ef" />
      <path d="m157.5 53.1-4.4-3.3-2.3 1 2 4.5-2 .9-5.3-11.8 4.1-1.9c2.3-1 4.4-1 5.6 1.6a4 4 0 0 1-.5 4.3l5 3.8Zm-9.3-8 1.7 3.9 2-.9c1.5-.7 1.8-1.7 1.3-3-.5-1.1-1.4-1.6-3.2-.8Z" fill="#faf6ef" />
      <path d="M236.8 60.6a174.3 174.3 0 0 1 162.9 118.7l57.5-18.7q-2.9-8.8-6.5-17.4A234.4 234.4 0 0 0 236.8 0Z" fill="#a35b61" />
      <path d="M333.2 64.3v-5.6l-2.1-1.2-2.4 4.3-2-1.1 6.4-11.3 4 2.3c2.2 1.2 3.4 3 2 5.4a4 4 0 0 1-3.8 2.2v6.2Zm1-12.2-2.1 3.6 1.8 1c1.5 1 2.5.6 3.2-.6.6-1.1.5-2.1-1.2-3Z" fill="#faf6ef" />
      <path d="m336.6 66.4 7-11 6.9 4.5-1.1 1.7-5-3.2-1.7 2.6 3.9 2.5-1.1 1.7-4-2.5-1.9 3.1 5.2 3.3-1.1 1.7Z" fill="#faf6ef" />
      <path d="m355.6 66-6.4 8.8-1.7-1.3 6.3-8.8-3-2.1 1.2-1.7 7.8 5.5-1.2 1.7Z" fill="#faf6ef" />
      <path d="M358.9 82.2 360 79l-3.5-2.8-3 1.8-1.7-1.4 11.7-7.3 1.6 1.4-4.4 13Zm3.3-9.6-3.9 2.4 2.4 2Z" fill="#faf6ef" />
      <path fill="#faf6ef" d="m361.8 84.7 8.4-9.8 1.7 1.5-8.5 9.7z" />
      <path d="m365.6 88 8.8-9.5L376 80l-7.4 8 4 3.7-1.4 1.5Z" fill="#faf6ef" />
      <path d="M334.7 377a174.2 174.2 0 0 1-200.3 0L99 426a234.5 234.5 0 0 0 271.4 0Z" fill="#a35b61" />
      <path d="m170.9 420.6-1.3 4.2-2.1-.7 4-12.3 3.6 1.2c3 1 4.8 2 3.7 5.5-1.1 3.4-3.8 3.5-5.6 2.9Zm2-6.4-1.4 4.5 2 .7c1.7.5 2.6 0 3.2-1.6.5-1.5.1-2.3-1.9-3Z" fill="#faf6ef" />
      <path d="m179.8 422.2 1.8-7.2 2.1.6-1.6 6.4c-.6 2.3-.7 4.5 2 5.1s3.5-1.2 4.1-3.6l1.7-6.4 2 .6-1.8 7.2c-.8 3.4-3 5.1-6.6 4.2s-4.6-3.5-3.7-6.9" fill="#faf6ef" />
      <path d="m192.2 431 2.4-12.8 3.3.7c3 .5 4.7 1.3 4.2 4.1a3.3 3.3 0 0 1-1.6 2.5 3 3 0 0 1 1.7 3.4c-.6 3-3.1 3.3-5.3 3Zm3.2-5.5-.7 4 2.1.4c1.7.3 2.9.1 3.2-1.4.3-1.6-.7-2.2-2-2.5Zm1-5-.6 3.3 2.2.4a1.5 1.5 0 0 0 2-1.3c.3-1.4-.5-1.8-1.7-2Z" fill="#faf6ef" />
      <path d="m204 433 1.7-12.8 2.2.3-1.5 10.8 5.5.7-.3 2Z" fill="#faf6ef" />
      <path fill="#faf6ef" d="m213.4 434.2 1.2-12.8 2.2.2-1.2 12.8z" />
      <path d="M218.3 428.3c.3-4.1 3.1-6.6 6.8-6.4a9 9 0 0 1 3.6 1l-.4 2a9.3 9.3 0 0 0-3.3-1c-2.7-.1-4.1 1.8-4.3 4.5s1.1 4.7 3.8 4.9a9 9 0 0 0 3.4-.6l.1 2a8.8 8.8 0 0 1-3.7.6c-3.7-.2-6.2-3-6-7" fill="#faf6ef" />
      <path d="m234.1 434.7.2-2.5a6.2 6.2 0 0 0 4.1 1.3c1.4 0 2.5-.5 2.5-1.6 0-1.4-1.5-1.8-3.1-2.4-2-.6-4-1.2-4-3.5 0-1.7 1-3.8 4.6-3.9a9 9 0 0 1 4 .9l-.1 2.3a8 8 0 0 0-3.8-1.1c-1.1 0-2.5.4-2.5 1.5s1.3 1.5 2.7 2c2.1.5 4.6 1.2 4.6 4 0 1.5-.5 3.8-5 3.9a9 9 0 0 1-4.2-.9" fill="#faf6ef" />
      <path d="m246 435-1-12.8 8.2-.6.1 2-5.9.5.2 3 4.7-.3.1 2-4.6.4.2 3.6 6.1-.4.2 2Z" fill="#faf6ef" />
      <path d="M255.2 427.9c-.5-4 1.8-7 5.5-7.5a9 9 0 0 1 3.7.3v2a9.3 9.3 0 0 0-3.4-.3c-2.7.4-3.8 2.5-3.4 5.2s2 4.5 4.6 4.1a9 9 0 0 0 3.2-1.2l.5 2a8.7 8.7 0 0 1-3.5 1.2c-3.7.5-6.7-1.8-7.2-5.8" fill="#faf6ef" />
      <path d="m271.3 421 2 10.7-2.2.4-2-10.7-3.6.7-.4-2 9.5-1.8.3 2Z" fill="#faf6ef" />
      <path d="M276.7 424.5c-1-4 1-7.1 4.6-8s6.8 1 7.8 5-1 7.1-4.6 8-6.8-1-7.8-5m2.4-.5c.6 2.6 2.4 4.2 5 3.5s3.3-2.9 2.7-5.5-2.4-4.2-5-3.5-3.4 2.9-2.7 5.5" fill="#faf6ef" />
      <path d="m300.6 424.6-4.1-3.8-2.4.7 1.5 4.7-2.1.7-4-12.4 4.4-1.3c2.4-.8 4.5-.5 5.4 2.2a4 4 0 0 1-1 4.2l4.5 4.3Zm-8.4-9 1.3 4 2-.6c1.6-.6 2-1.5 1.7-2.8-.4-1.3-1.3-1.8-3.1-1.2Z" fill="#faf6ef" />
      <path d="m171 441.2-1.3 4.2-2-.7 3.9-12.3 3.6 1.2c3 1 4.8 2 3.7 5.4-1 3.5-3.8 3.5-5.5 3Zm2.1-6.4-1.4 4.5 2 .7c1.7.5 2.6 0 3.1-1.6.5-1.5.2-2.3-1.8-3Z" fill="#faf6ef" />
      <path d="m185.8 450-1.4-5.4-2.4-.6-1.2 4.7-2.2-.6 3.3-12.5 4.4 1.2c2.5.6 4 2 3.4 4.7a4 4 0 0 1-3.1 3l1.4 6.1Zm-2.2-12-1.1 4 2 .5c1.7.5 2.6-.1 3-1.4.3-1.3-.1-2.2-2-2.7Z" fill="#faf6ef" />
      <path d="M191 444.7c.7-4 3.9-6.1 7.5-5.4s5.8 3.8 5 7.8-3.9 6-7.5 5.4-5.8-3.8-5-7.8m2.3.4c-.5 2.6.5 4.9 3 5.4s4.3-1.3 4.8-3.9-.4-4.8-3-5.3-4.3 1.2-4.8 3.8" fill="#faf6ef" />
      <path d="M205.2 447.3c.5-4 3.5-6.3 7.2-5.9a9 9 0 0 1 3.6 1.2l-.5 2a9.3 9.3 0 0 0-3.3-1.2c-2.7-.3-4.3 1.5-4.6 4.2s.8 4.8 3.4 5.1a9 9 0 0 0 3.5-.3v2a8.7 8.7 0 0 1-3.7.4c-3.7-.5-6-3.5-5.5-7.5" fill="#faf6ef" />
      <path d="m217.4 449.7.4-7.4 2.2.1-.4 6.6c-.1 2.4.2 4.6 3 4.7s3.2-1.9 3.4-4.3l.3-6.6 2.2.1-.4 7.4c-.2 3.5-2 5.7-5.7 5.5s-5.2-2.6-5-6.1" fill="#faf6ef" />
      <path d="M238.7 455.9 236 451h-2.5v4.9h-2.2v-13h4.5c2.5 0 4.4 1 4.5 3.8a4 4 0 0 1-2.3 3.7l3 5.5Zm-5.3-11.1v4.2h2.1c1.8 0 2.5-.8 2.5-2.2 0-1.3-.6-2-2.6-2Z" fill="#faf6ef" />
      <path d="m243.3 455.7-.8-12.9 8.2-.4.1 2-6 .4.3 3 4.6-.2.1 2-4.6.3.2 3.7 6.1-.4.1 2Z" fill="#faf6ef" />
      <path d="m265.2 453.7-2.3-8.8-2.6 6.6-4.1-5.7v9l-2.3.3.2-13 1.8-.3 4 5.6 2.4-6.4 1.8-.2 3.3 12.6Z" fill="#faf6ef" />
      <path d="m270.4 452.8-2.5-12.7 8-1.5.4 2-5.8 1.2.6 3 4.5-.9.4 2-4.6 1 .7 3.5 6-1.2.4 2Z" fill="#faf6ef" />
      <path d="M289.4 448.6 281 441l2.3 9-2.1.6L278 438l2-.5 7.9 7-2.2-8.5 2.1-.5 3.2 12.5Z" fill="#faf6ef" />
      <path d="m295.8 435.3 3.3 10.3-2 .7-3.4-10.3-3.5 1-.7-1.9 9.1-3 .7 2Z" fill="#faf6ef" />
    </g>
    <g className="circular-diagram-circle-middle">
      <path d="M233.5 151.6V88A145.8 145.8 0 0 0 89.8 230.2h63.5a82.6 82.6 0 0 1 80.2-78.6" fill="#7e74a4" />
      <path d="m130.2 196.2 3.6 1.4-.7 1.8-10.7-4 1.2-3.2c1-2.7 2.1-4.2 5-3a3.6 3.6 0 0 1 2.4 5Zm-5.6-2.1 4 1.5.6-1.8c.6-1.4.2-2.2-1.2-2.8-1.4-.5-2-.3-2.7 1.5Z" fill="#faf6ef" />
      <path d="m139.7 184.4-5 .3-1 2 3.9 2-.9 1.7-10.3-5.1 1.8-3.7c1-2 2.5-3.1 4.8-2a3.6 3.6 0 0 1 2 3.2l5.6-.2Zm-11-.2 3.4 1.7.8-1.7c.7-1.4.4-2.2-.7-2.8-1-.5-1.9-.3-2.7 1.2Z" fill="#faf6ef" />
      <path d="M136.2 178.7c-3-2-3.8-5.2-2-8s5-3.5 8-1.6 3.8 5.2 2 8-5 3.5-8 1.6m1.1-1.8c2 1.3 4.1 1.2 5.4-.8s.4-4-1.6-5.2-4.1-1.2-5.4.8-.4 4 1.6 5.2" fill="#faf6ef" />
      <path d="M143.4 167.6c-2.8-2.3-3.2-5.6-1.1-8.2a8 8 0 0 1 2.5-2.1l1.3 1.3a8.2 8.2 0 0 0-2.4 2c-1.5 1.8-.8 3.8 1 5.3s4 1.6 5.5-.2a8 8 0 0 0 1.4-2.8l1.5 1a7.8 7.8 0 0 1-1.5 3c-2 2.5-5.3 3-8.2.7" fill="#faf6ef" />
      <path d="m154.8 162.7-8.4-7.9 5-5.2 1.4 1.3-3.7 3.8 2 1.9 2.8-3 1.4 1.2-2.9 3 2.4 2.2 3.7-3.9 1.4 1.3Z" fill="#faf6ef" />
      <path d="m160.5 156-1.2-1.9a5.5 5.5 0 0 0 3.4-1.5c1-.8 1.5-1.8.8-2.5-.8-1-2-.4-3.5.2-1.6.7-3.4 1.5-4.7 0-1-1.2-1.5-3.3.9-5.4a8 8 0 0 1 3.2-1.7l1.2 1.6a7 7 0 0 0-3.2 1.4c-.7.7-1.4 1.8-.7 2.5.6.8 1.7.3 2.9-.2 1.7-.8 3.8-1.8 5.5 0 .8 1 1.7 3-1.2 5.5a8 8 0 0 1-3.4 2" fill="#faf6ef" />
      <path d="m167.9 149.5-1.1-1.9a5.5 5.5 0 0 0 3.6-1.2c1-.7 1.6-1.6 1-2.4-.8-1-2-.6-3.5-.1-1.8.5-3.5 1.1-4.7-.5-1-1.2-1.2-3.3 1.3-5.2a8 8 0 0 1 3.4-1.5l1 1.8a7 7 0 0 0-3.2 1.1c-.8.6-1.7 1.6-1 2.4.5.8 1.6.5 2.9 0 1.8-.6 4-1.4 5.4.6.8 1 1.6 3-1.6 5.3a7.9 7.9 0 0 1-3.5 1.6" fill="#faf6ef" />
      <path fill="#faf6ef" d="m170.3 134.5 1.6-1 6.2 9.6-1.6 1z" />
      <path d="m186.8 138-8.7-4.6 4 7.3-1.8 1-5.4-10.2 1.7-.9 8.2 4.4-3.7-6.8 1.7-1 5.4 10.1Z" fill="#faf6ef" />
      <path d="M187.9 131.1c-1.4-3.3 0-6.4 3-7.7a9 9 0 0 1 3.4-.6l.5 1.7a9.5 9.5 0 0 0-3.2.6c-2.3 1-2.7 3-1.8 5.2 1 2.2 2.8 3.4 5.1 2.4a6.4 6.4 0 0 0 1.5-.8l-.9-2.2-1.3.6-.6-1.5 3-1.3 2 4.7a9.7 9.7 0 0 1-3.3 2.3c-3 1.2-6 0-7.4-3.4" fill="#faf6ef" />
      <path d="M318.1 230.2h63.5A145.8 145.8 0 0 0 238 88.2v63.4a82.6 82.6 0 0 1 80.2 78.6" fill="#7e74a4" />
      <path d="m287.2 139.1.3-3-3.6-1.8-2.1 2.1-1.8-.9 8.7-8.4 1.7.8L289 140Zm1-9-2.9 2.8 2.5 1.2Z" fill="#faf6ef" />
      <path d="m290.1 140 1.4-1.7a5.5 5.5 0 0 0 2.4 2.9c1.1.7 2.1.9 2.7 0 .6-1-.2-2-1.2-3.3-1.2-1.4-2.4-2.8-1.4-4.5.8-1.3 2.7-2.4 5.4-.7a8 8 0 0 1 2.6 2.6l-1.2 1.6a7 7 0 0 0-2.3-2.6c-.8-.5-2-1-2.6 0-.5.8.2 1.7 1 2.7 1.3 1.4 2.9 3.1 1.6 5.3-.7 1-2.3 2.5-5.6.4a8 8 0 0 1-2.8-2.6" fill="#faf6ef" />
      <path d="m298.3 145.2 1.6-1.6a5.5 5.5 0 0 0 2.1 3.1c1 .8 2 1 2.7.2.7-1 0-2-1-3.3-1-1.5-2-3-.9-4.7 1-1.2 3-2 5.4-.2a7.9 7.9 0 0 1 2.4 2.9l-1.4 1.4a7 7 0 0 0-2-2.8c-.8-.6-2-1-2.6-.2-.6.7 0 1.7.8 2.8 1.2 1.5 2.6 3.3 1 5.4-.7 1-2.4 2.3-5.5 0a8 8 0 0 1-2.6-3" fill="#faf6ef" />
      <path d="m306 151.9 7.7-8.6 5.4 4.8-1.2 1.4-4-3.5-1.8 2 3 2.8-1.1 1.3-3.1-2.7-2.2 2.4 4 3.6-1.1 1.4Z" fill="#faf6ef" />
      <path d="m319.4 165.2 5.3-6.2-6.1 1.5 2-5.9-6.6 4.6-1.3-1.5 9.5-6.5 1.1 1.2-2 5.7 5.8-1.5 1.1 1.2-7.4 8.9Z" fill="#faf6ef" />
      <path d="m322 168.4 9.3-6.8 1.7 2.4c1.6 2.1 2.2 3.8.2 5.2a2.9 2.9 0 0 1-2.5.7 2.7 2.7 0 0 1-1 3.2c-2.3 1.6-4 .3-5.2-1.3Zm5.4-1.6-3 2.1 1.2 1.6c.9 1.2 1.7 1.8 2.9 1s.9-1.9.2-2.8Zm3.7-2.7-2.5 1.8 1.2 1.6a1.3 1.3 0 0 0 2.1.4c1-.8.8-1.5.2-2.3Z" fill="#faf6ef" />
      <path d="m327.9 176.7 9.8-6 1 1.7-8.3 5 2.6 4.2-1.6 1Z" fill="#faf6ef" />
      <path d="m338 184.1-4.6 2.4-.9-1.8 4.6-2.3 3.8-6.4 1 1.8-2.9 4.7 5.5.4.9 1.8Z" fill="#faf6ef" />
      <path d="M339 337.3a145.6 145.6 0 0 0 42.7-102.6h-63.5a82.6 82.6 0 0 1-80.3 81.9V380a145.5 145.5 0 0 0 101-42.7" fill="#7e74a4" />
      <path d="m274 348.8-3.2-7.4-1.3 6.1-4.4-4.4 1.3 8-2 .5-1.7-11.4 1.5-.5 4.3 4.4 1.2-6 1.6-.4 4.7 10.5Z" fill="#faf6ef" />
      <path d="m286.2 344-2.1-2.3-3.7 1.6.2 3-1.8.9-.8-12.2 1.7-.7 8.3 8.9Zm-6.2-6.7.3 4 2.5-1Z" fill="#faf6ef" />
      <path d="m297.4 338-8.8-4.4 4 7.2-1.7 1-5.6-10 1.7-1 8.3 4.2-3.8-6.7 1.6-1 5.7 10Z" fill="#faf6ef" />
      <path d="m299.7 331-3.9-5.4 1.6-1.1 3.4 4.7c1.3 1.8 2.7 3 4.7 1.6s1.2-3.1-.1-4.9l-3.4-4.7 1.5-1.2 3.9 5.4c1.8 2.5 1.8 5-.9 6.9s-5 1.2-6.8-1.4" fill="#faf6ef" />
      <path d="m308.5 318.2 1.8 2 3-2.6 1.2 1.4-3 2.6 3.4 3.8-1.5 1.3-7.6-8.6 5.3-4.7 1.2 1.4Z" fill="#faf6ef" />
      <path d="m324.9 315.6-2.8-1.2-2.8 2.9 1.4 2.7-1.4 1.5-5.5-11 1.3-1.3 11.2 5Zm-8.3-3.7 1.9 3.7 1.8-2Z" fill="#faf6ef" />
      <path d="M323.2 309c-2.9-2.3-3.2-5.6-1.2-8.2a8 8 0 0 1 2.5-2l1.3 1.2a8.2 8.2 0 0 0-2.4 2c-1.4 1.8-.8 3.9 1 5.3s4 1.6 5.5-.2a7.9 7.9 0 0 0 1.4-2.8l1.5 1a7.8 7.8 0 0 1-1.4 3c-2 2.5-5.4 3-8.2.7" fill="#faf6ef" />
      <path d="m329.3 294 8.1 5.2-1 1.6-8.1-5.2-1.8 2.7-1.6-1 4.7-7 1.5 1Z" fill="#faf6ef" />
      <path d="m336.5 291.4-5.9-3 .9-1.7 5.2 2.7c2 1 3.8 1.4 4.9-.7s-.4-3.4-2.3-4.3l-5.2-2.7.8-1.7 5.9 3c2.8 1.4 3.9 3.7 2.4 6.5s-4 3.4-6.7 2" fill="#faf6ef" />
      <path d="m349.2 275-4.9.8-.8 2 4 1.6-.6 1.8-10.8-4 1.5-3.9c.8-2 2.1-3.4 4.5-2.5a3.6 3.6 0 0 1 2.4 3l5.4-.6Zm-10.9.9 3.5 1.3.7-1.7c.5-1.5.1-2.3-1-2.7-1-.5-2-.2-2.5 1.4Z" fill="#faf6ef" />
      <path d="m350.9 270.2-11.1-3.1 2-7 1.7.5-1.4 5 2.6.8 1.1-4 1.8.5-1.1 4 3.1.9 1.5-5.3 1.7.5Z" fill="#faf6ef" />
      <path d="M153.2 234.7H89.8A145.8 145.8 0 0 0 233.5 380v-63.4a82.6 82.6 0 0 1-80.3-81.9" fill="#7e74a4" />
      <path d="m126.6 284.3 10.3-5 .8 1.8-8.6 4.2 2 4.4-1.6.8Z" fill="#faf6ef" />
      <path d="M135.4 289.5c3-2 6.3-1.1 8 1.7s1 6-2.1 8-6.3 1-8-1.8-1-6 2.1-8m1.1 1.9c-2 1.2-3 3.1-1.7 5.1s3.4 2.1 5.4 1 2.9-3.3 1.7-5.3-3.4-2-5.4-.8" fill="#faf6ef" />
      <path d="M142.8 301.2c2.9-2.2 6.2-1.8 8.2.9a9 9 0 0 1 1.6 3.1l-1.5 1a9.5 9.5 0 0 0-1.5-3c-1.5-2-3.6-1.7-5.5-.3-1.9 1.5-2.5 3.5-1 5.6a6.4 6.4 0 0 0 1.3 1.1l1.8-1.4-.9-1 1.3-1.1 2 2.7-4 3.1a9.7 9.7 0 0 1-3-2.6c-2-2.5-1.6-5.8 1.2-8" fill="#faf6ef" />
      <path fill="#faf6ef" d="m146.3 314.7 8.6-7.7 1.2 1.4-8.5 7.7z" />
      <path d="m150 318 1.8-1.5a5.5 5.5 0 0 0 1.8 3.4c.9.9 1.8 1.3 2.6.6.8-1 .2-2.1-.5-3.5-.9-1.6-1.8-3.3-.4-4.7 1-1 3.1-1.7 5.4.4a8 8 0 0 1 2 3.2l-1.5 1.3a7 7 0 0 0-1.7-3.1c-.7-.7-1.8-1.3-2.6-.6-.6.7-.1 1.7.5 3 1 1.6 2.1 3.6.3 5.4-.8 1-2.6 2-5.5-.7a8 8 0 0 1-2.1-3.2" fill="#faf6ef" />
      <path d="m167.5 321.4-6 7.5-1.6-1.2 6-7.5-2.5-2 1.2-1.5 6.6 5.3-1.2 1.5Z" fill="#faf6ef" />
      <path fill="#faf6ef" d="m166 332.4 6.7-9.4 1.5 1-6.6 9.5z" />
      <path d="M173 330.3c1.8-3 5.1-3.9 8-2.1a8 8 0 0 1 2.3 2.2l-1 1.5a8.2 8.2 0 0 0-2.3-2.2c-2-1.2-4-.3-5.2 1.7s-1.1 4.2.9 5.4a8 8 0 0 0 2.9 1l-.8 1.7a7.8 7.8 0 0 1-3-1.1c-2.9-1.8-3.7-5-1.8-8" fill="#faf6ef" />
      <path d="m180.5 340.7 1.2-1.9a5.5 5.5 0 0 0 2.7 2.7c1.1.5 2.2.6 2.6-.3.5-1.1-.4-2-1.5-3.1-1.3-1.3-2.7-2.6-1.8-4.4.6-1.4 2.4-2.6 5.2-1.3a8 8 0 0 1 2.9 2.4l-1 1.7a7 7 0 0 0-2.6-2.4c-.9-.4-2.2-.7-2.6.3-.4.8.4 1.6 1.3 2.5 1.5 1.4 3.2 2.9 2 5.2-.5 1.1-1.9 2.7-5.4 1a8 8 0 0 1-3-2.4" fill="#faf6ef" />
    </g>
    <g className="circular-diagram-circle-inner">
      <path d="M290 234a54.4 54.4 0 1 1-54.3-54.3 54.4 54.4 0 0 1 54.4 54.4" fill="#49716b" />
      <path d="M198.2 234.9c0-5.1 3.3-8.5 8-8.5s8 3.4 8 8.5-3.4 8.4-8 8.4-8-3.3-8-8.4m3 0c0 3.3 1.6 5.8 5 5.8s5-2.5 5-5.8-1.7-5.9-5-5.9-5 2.5-5 5.9" fill="#faf6ef" />
      <path d="m226.1 243-3.4-6.1h-3.1v6h-2.8v-16.1h5.7c3.2 0 5.6 1.1 5.6 4.7a5 5 0 0 1-2.8 4.6l3.7 6.9Zm-6.5-14v5.3h2.6c2.2 0 3-1 3-2.7 0-1.6-.7-2.6-3.1-2.6Z" fill="#faf6ef" />
      <path fill="#faf6ef" d="M231.1 226.8h2.7V243h-2.7z" />
      <path d="M236.5 234.9c0-5.1 3.4-8.4 8.1-8.4a12.8 12.8 0 0 1 4.9.9l-.3 2.5a13.4 13.4 0 0 0-4.5-.9c-3.6 0-5.2 2.5-5.2 5.8 0 3.5 1.8 6 5.4 6a9 9 0 0 0 2.4-.4v-3.2h-2v-2.3h4.7v7.2a13.7 13.7 0 0 1-5.6 1.2c-4.5 0-7.9-3.3-7.9-8.5" fill="#faf6ef" />
      <path fill="#faf6ef" d="M253 226.8h2.6V243H253z" />
      <path d="m269.8 243-7.8-11.7V243h-2.7v-16.2h2.7l7.3 11v-11h2.7V243Z" fill="#faf6ef" />
    </g>
  </svg>
);

CircularDiagram.defaultProps = {
  highlightColor: null,
};

export default CircularDiagram;
