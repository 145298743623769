import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import IndustryContext from '../industry-context';
import IndustrySelector from '../industry-selector';
import { SesameIcon, TantalumIcon } from '../industry-selector/icons';

import { textContentFunction } from '../../utils/text';

import './industry-description.scss';

const CHANGING_DURATION = 1000;

const IndustryDescription = ({ getText }: { getText: textContentFunction}) => {
  const { industry } = useContext(IndustryContext);
  const [isChanging, setIsChanging] = useState(false);
  const localTimeout = useRef(null);
  useEffect(() => {
    clearTimeout(localTimeout.current);
  }, []);

  useEffect(() => {
    clearTimeout(localTimeout.current);
    setIsChanging(true);

    localTimeout.current = setTimeout(() => {
      setIsChanging(false);
    }, CHANGING_DURATION);
  }, [industry]);

  return (

    <div className={`industry-description${isChanging ? ' is-changing' : ''}`}>
      <IndustrySelector />
      <div className="industry-description-content">
        <article>
          <div className="industry-description-resource-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 240 480"
            >
              <path
                d="M121 480V0"
                fill="none"
                stroke="currentColor"
                strokeWidth="8"
                className="industry-description-resource-icon-line"
              />
              <g
                fill="#b02a3c"
                strokeWidth="8"
                className="industry-description-resource-icon-circle"
              >
                <circle
                  cx="119.5"
                  cy="119.3"
                  r="116.6"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  transform="matrix(.99535 0 0 .99527 1 128)"
                />

              </g>
            </svg>
            <div className="industry-description-resource-icon-resource">
              {industry === 'mining' ? TantalumIcon : SesameIcon}
            </div>
            <div className="industry-description-resource-icon-resource-label">
              {industry === 'mining' ? 'Tantalum' : 'Sesame'}
            </div>
          </div>
          <div className="industry-description-text">
            {industry === 'mining' ? getText('Tantalum Introduction') : getText('Sesame Introduction')}
          </div>
          <div className="industry-description-text-secondary">
            {industry === 'mining' ? getText('Tantalum Due Diligence') : getText('Sesame Due Diligence')}
          </div>
          {industry === 'agriculture' ? (
            <div className="industry-description-text">
              {getText('Sesame Ethiopia Civil War')}
            </div>
          ) : ''}
        </article>
      </div>
    </div>
  );
};

export default IndustryDescription;
