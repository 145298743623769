import React from 'react';
import ReactMarkdown from 'react-markdown';

export interface textFunctionOptions {
  noParagraphs?: boolean // Set to true to not render paragraphs
  stringOnly?: boolean // Set to true to just return string
}

export type textContentFunction =
  (title: string, options?: textFunctionOptions) => React.ReactElement | string;

export interface titleBodyPair {
  title: string
  body: string
}

const DEFAULT_COMPONENTS = {
  h5({ children } : { children : any[] }) {
    const [contents] = children;
    if (contents === 'DD' || contents === 'C') {
      return (
        <h5 className="dd-header" title="Due diligence">
          <span>{contents}</span>
        </h5>
      );
    }
    return <h5>{children}</h5>;
  },
};

/**
  * Creates a function which returns text for a given title.
  */
const getTextFunctionFromData = (allContents : titleBodyPair[]) => {
  const textContent: { [key: string]: string } = {};

  allContents.forEach(({ title, body }) => {
    textContent[title] = body;
  });

  const txtFunc: textContentFunction = (
    title: string,
    { noParagraphs, stringOnly }: textFunctionOptions = {},
  ) => {
    if (stringOnly) {
      return textContent[title];
    }
    return (
      <ReactMarkdown
        skipHtml
        components={
          noParagraphs ? { p: React.Fragment, ...DEFAULT_COMPONENTS } : DEFAULT_COMPONENTS
        }
      >
        {textContent[title] || `String **${title}** not found`}
      </ReactMarkdown>
    );
  };

  return txtFunc;
};

export default getTextFunctionFromData;
