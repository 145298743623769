import React, { useState, useEffect } from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { useWindowWidth } from '@react-hook/window-size';

import './dot-indicator.scss';

interface SectionEntry {
  title: string
  node: HTMLElement
  isActive: boolean
}

const DotIndicator = () => {
  const [sectionData, setSectionData] = useState([] as SectionEntry[]);

  const scrollPosition = useScrollPosition(20);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const index = sectionData.filter(
      ({ node }) => node.getBoundingClientRect().y <= window.innerHeight / 2,
    ).length - 1;
    setSectionData(sectionData.map(
      (section, i) => ({ ...section, isActive: i === index }),
    ));
  }, [windowWidth, scrollPosition]);

  const prepareSectionData = () => {
    // Sections will never change, so we can load them on mount
    const domSections = Array.from(
      document.querySelectorAll('[data-section-title]'),
    );
    setSectionData(domSections.map((node) => {
      const htmlNode = node as HTMLElement;
      const title = htmlNode.dataset.sectionTitle;
      return { title, node: htmlNode, isActive: false };
    }));
  };

  useEffect(prepareSectionData, []);

  return (
    <div className="dot-indicator hide-on-mobile">
      <div className="dot-indicator-inner">
        <ul className="dot-indicator-list">
          {sectionData.map(
            ({ title, node, isActive }) => (
              // eslint-disable-next-line
              <li
                className={isActive ? 'is-active' : ''}
                key={title}
                data-title={title}
                onClick={() => node.scrollIntoView({ block: 'start', behavior: 'smooth' })}
              >
                {title}
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  );
};

export default DotIndicator;
