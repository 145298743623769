import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import countryData from './data';

import '../map/map.scss';

const GEO_URL = './world.geojson';

const MAP_WIDTH = 600;
const MAP_HEIGHT = 400;

const MAX = 30.8;

interface CountryNode {
  properties: {
    name: string
  }
}

const getColorForName = (name: string) => {
  if (countryData[name] === 0) {
    return 'lightgrey';
  }
  return `rgba(163,91,97,${countryData[name] / MAX})`;
};

const createMap = async (svg: any) => {
  const projection = d3.geoNaturalEarth1()
    .scale(MAP_WIDTH / 1.6 / Math.PI)
    .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2]);

  const data = await d3.json(GEO_URL) as { features: any };

  svg.append('g')
    .selectAll('path')
    .data(data.features)
    .join('path')
    .attr('d', d3.geoPath().projection(projection))
    .attr('name', ({ properties: { name } }: CountryNode) => name)
    .style('fill', ({ properties: { name } }: CountryNode) => getColorForName(name))
    .style('stroke', '#49716B');
};

const MapDiagram = () => {
  const ref = useRef(null as SVGSVGElement);

  useEffect(() => {
    createMap(d3.select(ref.current));

    return () => {
      ref.current.innerHTML = '';
    };
  }, []);

  return (
    <svg
      className="map-diagram"
      preserveAspectRatio="xMinYMin meet"
      ref={ref}
      viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}
    />
  );
};

export default MapDiagram;
