import React from 'react';

export const LogoPACE = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 137">
    <g clipPath="url(#apace)">
      <path
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="5.6"
        d="M64.9 134.2h69.4V2.8H2.8v68.7"
      />
      <path
        fill="#ffffff"
        d="M15.6 101.3c3.2 0 5-1.9 5-4.9 0-3-1.9-4.8-5-4.8H9.2v9.7h6.4ZM6
        88.9h10c5.2 0 8 3.3 8 7.5 0 4.1-2.8 7.6-8 7.6H9.3v10H6V88.8Zm31.4
        15.7h9l-4.5-13.1-4.5 13ZM40 88.9h3.7l9.3 25h-3.3l-2.4-6.6h-11l-2.2
        6.6h-3.4L40 89Zm34.5 22.6c2.3 0 4.1-.5 5.7-1l.5 2.6c-1.4.7-4 1.3-6.8
        1.3-7.6 0-12.4-5.1-12.4-13 0-8 4.8-13 12.4-13 2.8 0 5.4.6 6.8 1.3l-.5
        2.7c-1.6-.6-3.4-1-5.7-1-6 0-9.7 3.6-9.7 10s3.6 10 9.7
        10m17.6-22.5h16.2v2.8H95.4v8h12v2.8h-12v8.6h12.9v2.8H92V89ZM183.3 53c1.4
        0 2.2-.8 2.2-2 0-1.4-.8-2.2-2.2-2.2h-2.8V53h2.8Zm-4.3-5.4h4.5c2.3 0 3.5
        1.5 3.5 3.3 0 1.8-1.3 3.4-3.5 3.4h-3v4.4H179V47.6Zm15.4 7.4h-2.2c-1
        0-1.6.5-1.6 1.5 0 .9.6 1.3 1.5 1.3 1.4 0 2.3-1 2.3-2.2V55Zm.1 3.7v-1.4a3
        3 0 0 1-2.8 1.5c-1.5 0-2.5-.9-2.5-2.3 0-1.7 1.2-2.5
        2.8-2.5h2.4v-.7c0-1-.6-1.5-1.9-1.5a6 6 0 0 0-2.5.5l-.2-1.1a7 7 0 0 1
        3-.7c2 0 3 .9 3 2.7v5.5h-1.3ZM203 52c-1.5 0-2.2 1.1-2.2
        2.7v4h-1.4v-8h1.4v1.7c.3-1 1-1.8 2.2-1.8l.7.1V52h-.7m5.6 6.8c-1.4
        0-2-.7-2-2V52h-1.1v-1.2h1v-2h1.5v2h1.9V52H208v4.8c0 .6.2 1 1
        1l.9-.1v1l-1.3.2Zm9.4-.1v-5.2c0-1.1-.5-1.7-1.5-1.7-1.3 0-2.2 1-2.2
        2.6v4.3h-1.5v-8h1.4v1.6c.5-1 1.4-1.8 2.7-1.8 1.8 0 2.6 1 2.6
        2.8v5.4h-1.4Zm6-4.6h4V54c0-1.3-.5-2.3-1.9-2.3s-2 .8-2.2 2.5Zm0 1c0 1.7.7
        2.7 2.5 2.7 1 0 1.8-.3 2.5-.6l.2 1a6 6 0 0 1-2.9.6c-2.6
        0-3.8-1.7-3.8-4.1 0-2.4 1.2-4.2 3.6-4.2 2.3 0 3.2 1.6 3.2
        3.8v.9H224Zm12-3.1c-1.5 0-2.3 1.1-2.3 2.7v4h-1.4v-8h1.4v1.7c.3-1 1-1.8
        2.2-1.8l.7.1V52h-.7Zm5.4 6.8c-.9 0-2-.2-2.9-.6l.3-1a7 7 0 0 0 2.6.6c1 0
        1.6-.4 1.6-1.2 0-.7-.4-1-1.7-1.3-2-.6-2.8-1.2-2.8-2.5 0-1.5 1.1-2.3
        3-2.3 1 0 2 .3 2.7.6l-.3 1c-.7-.2-1.5-.5-2.4-.5-1 0-1.6.3-1.6 1 0 .9.6 1
        1.9 1.4 1.5.5 2.6 1 2.6 2.4 0 1.5-1.1 2.4-3
        2.4m11.2-.1v-5.2c0-1.1-.6-1.7-1.6-1.7-1.3 0-2.2 1-2.2
        2.6v4.3h-1.5V47.6h1.5v4.7c.4-1 1.3-1.8 2.6-1.8 1.8 0 2.6 1 2.6
        2.8v5.4h-1.4Zm4.9 0v-8h1.4v8h-1.4Zm.7-11.1c.6 0 1 .4 1 1 0 .5-.4 1-1 1a1
        1 0 0 1-1-1c0-.6.4-1 1-1Zm10.1 7.1c0-1.8-.5-3-2-3-1.6 0-2.3 1.4-2.3 3s.7
        3 2.2 3c1.6 0 2.1-1.3 2.1-3Zm-4.3 2.5v4.4h-1.5V50.7h1.4v1.5c.5-1 1.4-1.7
        2.7-1.7 2.2 0 3.2 1.9 3.2 4.2 0 2.3-1 4.1-3.2 4.1-1.3
        0-2.1-.6-2.6-1.6m15.5-2.7h4l-2-5.8-2 5.8Zm1.2-7h1.6l4.1
        11.2H285l-1-3h-5l-1 3h-1.4l4-11.1m11.4 4.1c-1.4 0-2.1 1.2-2.1 2.8 0
        1.7.7 2.9 2.1 2.9 1.4 0 2.3-1.2 2.3-2.9 0-1.6-.8-2.8-2.3-2.8Zm3.7 6.6c0
        2-1 3.5-3.7 3.5-1 0-2-.2-2.8-.5l.2-1.1a7 7 0 0 0 2.6.5c1.8 0 2.3-1
        2.3-2.4v-1.5a3 3 0 0 1-2.7 1.7c-2.3 0-3.2-1.9-3.2-4 0-2 1-4 3.2-4 1.2 0
        2.2.6 2.7 1.7v-1.5h1.4v7.6ZM304 55h-2c-1.1 0-1.7.5-1.7 1.5 0 .9.6 1.3
        1.6 1.3 1.3 0 2.3-1 2.3-2.2V55Zm.1 3.7v-1.4a3 3 0 0 1-2.8 1.5c-1.5
        0-2.4-.9-2.4-2.3 0-1.7 1.1-2.5 2.8-2.5h2.4v-.7c0-1-.7-1.5-2-1.5a6 6 0 0
        0-2.4.5l-.3-1.1a7 7 0 0 1 3-.7c2 0 3 .9 3 2.7v5.5h-1.3m4.9
        0v-8h1.4v8H309Zm.7-11.1c.6 0 1 .4 1 1 0 .5-.4 1-1 1a1 1 0 0
        1-1-1c0-.6.4-1 1-1Zm9.5 11.1v-5.2c0-1.1-.5-1.7-1.6-1.7-1.2 0-2.2 1-2.2
        2.6v4.3H314v-8h1.4v1.6c.4-1 1.3-1.8 2.7-1.8 1.8 0 2.6 1 2.6
        2.8v5.4h-1.5Zm7.3.1c-.9 0-2-.2-2.9-.6l.3-1a7 7 0 0 0 2.6.6c1 0 1.6-.4
        1.6-1.2 0-.7-.4-1-1.7-1.3-2-.6-2.8-1.2-2.8-2.5 0-1.5 1-2.3 3-2.3 1 0 2
        .3 2.7.6l-.3 1c-.7-.2-1.5-.5-2.4-.5-1 0-1.6.3-1.6 1 0 .9.6 1 1.9 1.4
        1.5.5 2.6 1 2.6 2.4 0 1.5-1.1 2.4-3 2.4Zm8.3 0c-1.4
        0-2-.7-2-2V52h-1.1v-1.2h1.1v-2h1.5v2h1.8V52h-1.8v4.8c0 .6.2 1 1
        1l.8-.1v1l-1.3.2ZM184.2 85.4a7 7 0 0 0 2.5-.5l.3 1.2c-.7.3-1.8.5-3
        .5-3.5 0-5.6-2.2-5.6-5.7s2.1-5.8 5.5-5.8c1.3 0 2.4.3 3 .6l-.2 1.2a7 7 0
        0 0-2.5-.5c-2.7 0-4.3 1.7-4.3 4.5s1.6 4.5 4.3 4.5m11
        1v-5.2c0-1-.5-1.7-1.6-1.7-1.3 0-2.2 1-2.2 2.7v4.2H190v-11h1.4V80c.5-1
        1.3-1.8 2.7-1.8 1.8 0 2.5 1 2.5 2.7v5.4h-1.4Zm4.9
        0v-8h1.5v8H200Zm.8-11c.5 0 .9.4.9 1 0 .4-.4.9-1 .9a1 1 0 0
        1-1-1c0-.5.5-1 1-1Zm6.1 11.2c-1.4 0-1.9-.8-1.9-2.1v-9.2h1.5v9.2c0 .6.2 1
        .8 1l.6-.2v1.1l-1 .2Zm6.7-1.1c1.5 0 2.2-1.4 2.2-3 0-1.7-.7-3-2.2-3-1.5
        0-2.1 1.2-2.1 3 0 1.7.6 3 2 3Zm-.4-7.2c1.3 0 2.2.7 2.6
        1.7v-4.7h1.5v11.1H216V85c-.5 1-1.4 1.7-2.7 1.7-2.2 0-3.2-1.8-3.2-4.1
        0-2.4 1-4.2
        3.2-4.2Zm12.6-3h7.1v1.3h-5.6v3.5h5.3v1.3h-5.3v3.8h5.7v1.2h-7.2v-11Zm13.7
        7.1 2.7 4h-1.5l-2-3-1.9 3h-1.5l2.7-4-2.5-4h1.5l1.7 2.9 1.8-2.8h1.5l-2.5
        3.9m11.2.1c0-1.8-.6-3-2-3-1.6 0-2.4 1.3-2.4 3 0 1.6.8 3 2.3 3 1.5 0
        2.1-1.3 2.1-3Zm-4.4 2.4v4.5H245v-11h1.4V80c.5-1 1.4-1.7 2.7-1.7 2.2 0
        3.1 1.8 3.1 4.2 0 2.3-1 4.1-3.1 4.1-1.3 0-2.2-.7-2.7-1.7m10.6 1.7c-1.3
        0-1.8-.8-1.8-2.1v-9.2h1.5v9.2c0 .6.2 1 .8 1l.6-.2v1.1l-1 .2m6.9-7.2c-1.6
        0-2.3 1.2-2.3 3 0 2 .7 3 2.2 3 1.5 0 2.2-1 2.2-3 0-1.8-.7-3-2.2-3Zm0
        7.2c-2.5 0-3.7-1.8-3.7-4.1 0-2.4 1.2-4.2 3.6-4.2 2.4 0 3.6 1.8 3.6 4.2 0
        2.3-1.2 4.1-3.6 4.1Zm6.6-.2v-8h1.4v8h-1.4Zm.7-11c.6 0 1 .4 1 1 0
        .4-.4.9-1 .9a1 1 0 0 1-1-1c0-.5.4-1 1-1Zm6.7 11.2c-1.4
        0-2-.7-2-2v-5h-1v-1.1h1v-2.1h1.5v2h1.9v1.3h-2v4.8c0 .6.3 1 1
        1l1-.2v1c-.3.2-.9.3-1.3.3Zm9-3.8h-2.2c-1 0-1.6.5-1.6 1.4 0 1 .6 1.4 1.5
        1.4 1.3 0 2.3-1 2.3-2.3v-.5Zm0 3.6v-1.3a3 3 0 0 1-2.7 1.5c-1.6
        0-2.5-1-2.5-2.3 0-1.7 1.2-2.5 2.8-2.5h2.4V81c0-1.1-.6-1.6-1.9-1.6a6 6 0
        0 0-2.5.6l-.2-1.2a7 7 0 0 1 3-.6c2 0 3 .9 3 2.7v5.4h-1.3Zm7.4.2c-1.5
        0-2-.7-2-2v-5h-1.2v-1.1h1.2v-2.1h1.4v2h1.9v1.3h-2v4.8c0 .6.3 1 1
        1l1-.2v1c-.3.2-.9.3-1.3.3Zm4.2-.2v-8h1.5v8h-1.5Zm.7-11c.6 0 1 .4 1 1 0
        .4-.4.9-1 .9a1 1 0 0 1-1-1c0-.5.4-1 1-1Zm7.4 4c-1.5 0-2.2 1.2-2.2 3 0 2
        .7 3 2.2 3 1.5 0 2.2-1 2.2-3 0-1.8-.7-3-2.2-3Zm0 7.2c-2.4
        0-3.6-1.8-3.6-4.1 0-2.4 1.2-4.2 3.6-4.2 2.5 0 3.7 1.8 3.7 4.2 0 2.3-1.2
        4.1-3.7 4.1Zm11.9-.2v-5.2c0-1-.5-1.7-1.6-1.7-1.2 0-2.2 1-2.2
        2.7v4.2h-1.4v-8h1.4v1.7c.4-1 1.3-1.8 2.7-1.8 1.8 0 2.6 1 2.6
        2.7v5.4h-1.5Z"
      />
    </g>
    <defs>
      <clipPath id="apace">
        <path fill="#fff" d="M0 0h336.1v137H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default { LogoPACE };
