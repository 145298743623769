import React from 'react';

const commonStrokeWidth = '1.3229';

const whiteColour = '#faf6ef';

export const phoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.1 219.1">
    <rect width="64.1" height="102.2" rx="6.9" transform="translate(78 56)" fill="none" stroke={whiteColour} strokeWidth="5" />
    <path d="M122 67a4 4 0 0 1-4 3h-17a4 4 0 0 1-4-3h-9v81h43V67Z" fill="none" stroke={whiteColour} strokeWidth="5" />
  </svg>
);

export const laptopIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.1 219.1">
    <path d="M122 141a4 4 0 0 1-4 4h-16a4 4 0 0 1-4-4H55v13h110v-13Z" fill="none" stroke={whiteColour} strokeWidth="5" />
    <path d="M161 141V76a8 8 0 0 0-8-8H67a8 8 0 0 0-8 8v65" fill="none" stroke={whiteColour} strokeWidth="5" />
    <path fill="none" stroke={whiteColour} strokeWidth="5" d="M68 78h84v54H68z" />
  </svg>
);

export const cameraIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.1 219.1">
    <g fill="none" stroke={whiteColour} strokeWidth="5">
      <path d="M136.8 115.7a27.3 27.3 0 1 1-27.2-27.2 27.3 27.3 0 0 1 27.2 27.2Z" />
      <path d="M127.1 115.7a17.5 17.5 0 1 1-17.5-17.5 17.5 17.5 0 0 1 17.5 17.5Z" />
      <path d="M52.5 81.8h114.2v67.9H52.5z" />
      <path d="M125.5 69.1H93.6l-4.2 12.7h40.3Z" />
      <path d="M59.5 75.5h11.8v6.3H59.5z" />
      <path d="M156.7 96.5a5.6 5.6 0 1 1-5.6-5.6 5.6 5.6 0 0 1 5.6 5.6Z" />
    </g>
  </svg>
);

export const cloudIcon = (
  <svg viewBox="0 0 57.9 57.9" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.6 32.7H38a5 5 0 0 0-7-2.8 7.3 7.3 0 0 0-13.6 2.8 4.7 4.7 0 0 0 0 9.4h23.3a4.7 4.7 0 1 0 0-9.4z" fill="none" stroke={whiteColour} strokeWidth="1.3229" />
    <path d="M30.7 22.2a1.7 1.7 0 1 1-1.7-1.7 1.7 1.7 0 0 1 1.7 1.7" fill={whiteColour} />
    <g fill="none" stroke={whiteColour} strokeLinejoin="round" strokeWidth="1.3229">
      <path d="M25.8 19.1a4.4 4.4 0 0 1 6.3 0" />
      <path d="M23.7 17a7.5 7.5 0 0 1 10.5 0" />
      <path d="M21.8 15A10.2 10.2 0 0 1 36 15" />
    </g>
  </svg>
);

export const prostheticIcon = (
  <svg viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(.26458)" fill="none" stroke={whiteColour} strokeWidth="5">
      <path d="M81 176.3s0-21-4.9-30.9c-4.9-9.9-27-21-21-32s19.5-23 32.7-9.3c13.2 13.7 19.7 10.5 22 17 2.3 6.5-10 6.1 0 55.2" />
      <circle cx="12.4" cy="12.4" r="12.4" transform="translate(103.2 77.6)" />
      <g strokeLinejoin="round">
        <path d="M111.4 73.1a14.9 14.9 0 0 1 21 21" />
        <path d="m115.6 90-20 20" />
        <path d="M115.6 68.8s3-20.4-12.4-28.7" />
        <path d="M135.8 90s18.7-9.5 33 4.2" />
      </g>
    </g>
  </svg>
);

export const hearingAidIcon = (
  <svg viewBox="0 0 57.9 57.9" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke={whiteColour} strokeWidth={commonStrokeWidth}>
      <path d="M32.7 33.7a3.8 3.8 0 1 1 3.8-3.7" />
      <path d="M36.1 41.1c0 2.3-1.7 4.2-4 4.3a4.2 4.2 0 0 1-4.3-4.2v-.9A5 5 0 0 0 25 36 10.1 10.1 0 1 1 40 26.2" />
      <path d="M23.3 27a6.6 6.6 0 1 1 13.2 0v4.3" />
      <path d="M37 31.3h-2a1.9 1.9 0 0 0-1.8 1.9v.4a1.9 1.9 0 0 0 1.9 1.9h1.1" />
      <path strokeLinejoin="round" d="M32.7 26.2V17" />
      <path d="m22.8 34.1-3.1 3.1A14.4 14.4 0 1 1 40 16.8l-3 3" />
    </g>
  </svg>
);

export const glassesIcon = (
  <svg viewBox="0 0 57.94 57.94" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke={whiteColour} strokeWidth={commonStrokeWidth}>
      <path d="M20.21 36.14h-1.27a6.5 6.5 0 0 1-6.53-6.5v-2.76a1.67 1.67 0 0 1 1.22-1.59 22.49 22.49 0 0 1 11.85 0 1.67 1.67 0 0 1 1.22 1.6v2.74a6.5 6.5 0 0 1-6.51 6.51z" />
      <path d="M39 36.14h-1.3a6.5 6.5 0 0 1-6.5-6.5v-2.76a1.67 1.67 0 0 1 1.21-1.59 22.49 22.49 0 0 1 11.85 0 1.67 1.67 0 0 1 1.25 1.6v2.74A6.5 6.5 0 0 1 39 36.14z" />
      <path d="M26.72 27.68a10.05 10.05 0 0 1 4.5 0" />
    </g>
  </svg>
);

export const GPSIcon = (
  <svg viewBox="0 0 57.9 57.9" xmlns="http://www.w3.org/2000/svg">
    <g stroke={whiteColour}>
      <path d="m34 22.7-5 5-5-5a7 7 0 1 1 10 0z" fill="none" strokeWidth={commonStrokeWidth} />
      <path d="M31.5 16.1a2.5 2.5 0 1 1-2.5-2.5 2.5 2.5 0 0 1 2.5 2.5Z" fill="none" strokeWidth={commonStrokeWidth} />
      <path d="M35.2 36.1c7 0 8 2.1 8 2.1V43H14.7v-6.2l3.5-.6 2.2-4.5h12.4l3.1 5" fill="none" strokeWidth="1.772686" />
      <path d="M22.4 43.4a2 2 0 1 1-2-2 2 2 0 0 1 2 2z" strokeWidth={commonStrokeWidth} fill="currentColor" />
      <path d="M40.3 43.4a2 2 0 1 1-2-2 2 2 0 0 1 2 2z" strokeWidth={commonStrokeWidth} fill="currentColor" />
    </g>
  </svg>
);

export const EVIcon = (
  <svg viewBox="0 0 57.9 57.9" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke={whiteColour} strokeWidth={commonStrokeWidth}>
      <path d="M17 30s-.3-1.6-.6-2.3c-.4-.7-3.6-.7-3.6 0s-.2 2 1.7 2.2c1.9.2 1.9.6 1.9.6" />
      <path d="M16.8 34.1s.1 2.7 2.8 2.5c2.8-.1.1-3.3-1-3.7a1.3 1.3 0 0 0-1.8 1.2z" />
      <path d="M21.3 43.1s-1.1-2-1-3.4c.1-1.4 4.5-1.7 8.7-1.7" />
      <path d="M20 43.3V45a1.7 1.7 0 0 1-1.6 1.7h-.6a1.7 1.7 0 0 1-1.6-1.7v-1.7" />
      <path d="M41.3 31c-.2-.4-2-4.3-3.2-6.4a3 3 0 0 0-2.1-1.4 68.5 68.5 0 0 0-14 0 3 3 0 0 0-2.2 1.4c-1.2 2.1-3 6-3.2 6.4-.3.3-2.6 2-2 6.2.4 4 0 5.9 1.5 5.9h25.8c1.4 0 1-1.8 1.5-6 .5-4-1.8-5.8-2-6.1z" />
      <path d="M35.5 25.5c-1.6-.5-5.9-.5-6.5-.6-.7 0-5 .1-6.6.6-1 1.6-2.4 3.7-1.3 3.7h15.7c1.1 0-.3-2-1.3-3.7z" />
      <path d="M40.9 30s.3-1.6.7-2.3c.3-.7 3.6-.7 3.5 0 0 .7.2 2-1.6 2.2-2 .2-2 .6-2 .6" />
      <path d="M41.1 34.1s0 2.7-2.8 2.5c-2.8-.1 0-3.3 1-3.7a1.3 1.3 0 0 1 1.8 1.2z" />
      <path d="M36.6 43.1s1.2-2 1-3.4c0-1.4-4.5-1.7-8.7-1.7" />
      <path d="M38 43.3V45a1.7 1.7 0 0 0 1.6 1.7h.5a1.7 1.7 0 0 0 1.7-1.7v-1.7" />
      <path d="M33.5 13.7h-3.3V8l-5.6 5.7h3.2v5.6z" />
    </g>
  </svg>
);

export const asianCookingIcon = (
  <svg viewBox="0 0 52.8 52.8" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="#faf5ee" strokeWidth="1.3">
      <path d="M37.4 29.2v.3c-.4 4.4-5.7 8-12.2 8-6.5 0-11.7-3.6-12.2-8a3.2 3.2 0 0 1 0-.4c.4-1.2 5.7-2.2 12.2-2.2 6.6 0 12 1 12.2 2.3z" />
      <path d="M21.5 27h3.7c6.6 0 12 1 12.2 2.2a.6.6 0 0 1 0 .1.5.5 0 0 1 0 .2c-.4 1.2-5.8 2.2-12.2 2.2h-2.4a43 43 0 0 1-6.1-.7c-2-.4-3.4-.9-3.7-1.4a.5.5 0 0 1 0-.3.4.4 0 0 1 0-.2c0-.4.8-.8 1.8-1" />
    </g>
    <path d="m22.4 26.5 20-7a.4.4 0 0 1 .6.2l.2.6a.4.4 0 0 1-.3.5l-20.3 6.4a.4.4 0 0 1-.5-.2.4.4 0 0 1 .3-.5zM21.9 25.2 40.5 15a.4.4 0 0 1 .5.2l.3.6a.4.4 0 0 1-.1.5l-19 9.7a.4.4 0 0 1-.5-.1.4.4 0 0 1 .2-.6z" fill="#faf5ee" />
  </svg>
);

export const bakedGoodsIcon = (
  <svg viewBox="0 0 52.8 52.8" xmlns="http://www.w3.org/2000/svg" stroke="#faf5ee" strokeWidth="1.3" strokeLinecap="round">
    <path d="M41.5 26.7V29a1.9 1.9 0 0 1-1.8 1.8H14.5a1.9 1.9 0 0 1-1.9-1.8V26h0c.6-4.3 6.8-7.6 14.5-7.6 7.6 0 13.9 3.3 14.4 7.6a3.9 3.9 0 0 1 0 .6z" fill="none" />
    <path d="M27 30.7c.4 3.8-3.6 4.3-8.2 4.3s-8.3-.9-8.3-4.3c0-3.6 3.7-4.3 8.3-4.3 4.6 0 8 .7 8.3 4.3z" fill="currentColor" />
    <path d="M18.2 26.4s-2 .8-3 2.5M21.2 26.6a5.9 5.9 0 0 0-2.6 2.3M19 22.6s.4-3.7 5.7-4M32 22.8s0-2 2.5-3M22.6 23.5s0-5.4 5.9-5M27.1 23.6s-.3-5.2 5.5-4.5" fill="none" />
  </svg>
);

export const hummusIcon = (
  <svg viewBox="0 0 52.77 52.77" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1 32.1v-.27c-.02-.11-.03.04-.03-.07.48-1 6.1-1.77 12.96-1.77 7.02 0 12.74.82 13 1.83l-.02.2" fill="none" stroke="#faf5ee" strokeWidth="1.32" />
    <path d="M25.03 29.73c7.02 0 12.74 1.04 13 2.35a.56.56 0 0 1 0 .09.45.45 0 0 1-.02.16c-.45 1.27-6.08 2.28-12.98 2.28-.83 0-1.65-.01-2.45-.04a47.55 47.55 0 0 1-6.58-.64c-2.14-.4-3.57-.92-3.9-1.5" fill="none" stroke="#faf5ee" strokeWidth="1.32" />
    <path d="M38 31.7c.02.37.03.48.03.86-.48 3.35-6.1 5.98-12.97 5.98-7.02 0-12.73-2.76-12.99-6.2l.02-.67" fill="none" stroke="#faf5ee" strokeWidth="1.32" />
    <path d="M12.2 31.83s5.22-4.97 12.22-2.44c3.38 1.22 6.37 4.06 13.28 2.44" fill="currentColor" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" />
    <path d="M25.86 25.46c-1.9 0-3.29-.77-3.52-1.96-.16-.8.21-1.62 1.05-2.3a6.56 6.56 0 0 1 2.86-1.27 8.39 8.39 0 0 1 1.6-.17 4.3 4.3 0 0 1 1.99.42.66.66 0 0 0 .3.08.43.43 0 0 0 .08-.01l13.76-2.72a1.11 1.11 0 0 1 .21-.02 1.62 1.62 0 0 1 1.48 1.23 1.78 1.78 0 0 1-.16 1.07 1.3 1.3 0 0 1-.89.78l-13.41 2.65a.68.68 0 0 0-.4.29c-.73 1.05-2.3 1.55-3.5 1.79a7.53 7.53 0 0 1-1.45.14z" fill="currentColor" />
    <path d="M44.2 18.17a.96.96 0 0 1 .83.7c.07.4-.2 1-.53 1.08l-13.42 2.64a1.31 1.31 0 0 0-.82.56c-.49.72-1.62 1.24-3.07 1.52a6.88 6.88 0 0 1-1.33.13c-1.51 0-2.7-.55-2.88-1.43-.22-1.13 1.3-2.38 3.4-2.8a7.72 7.72 0 0 1 1.48-.15 3.67 3.67 0 0 1 1.68.34 1.3 1.3 0 0 0 .6.15 1.09 1.09 0 0 0 .2-.02l13.77-2.7a.44.44 0 0 1 .08-.02m0-1.32a1.77 1.77 0 0 0-.34.04l-13.7 2.7h-.01a4.95 4.95 0 0 0-2.28-.5 9.04 9.04 0 0 0-1.74.2c-2.9.56-4.8 2.43-4.43 4.34.3 1.51 1.93 2.5 4.17 2.5a8.2 8.2 0 0 0 1.58-.16c1.9-.38 3.22-1.07 3.9-2.07a.22.22 0 0 1 .03-.02l13.38-2.64a1.96 1.96 0 0 0 1.36-1.15 2.45 2.45 0 0 0 .2-1.48 2.26 2.26 0 0 0-2.12-1.76z" fill="#faf5ee" />
  </svg>
);

export const skinCreamIcon = (
  <svg viewBox="0 0 52.77 52.77" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.87 21.46h20.06v7.4a2.83 2.83 0 0 1-2.83 2.83H15.7a2.83 2.83 0 0 1-2.83-2.83zM12.88 23.94h20.05" fill="currentColor" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" />
    <path fill="#faf5ee" stroke="currentColor" strokeWidth="1.52" strokeLinecap="round" d="M18.03 25.87h9.74v3.9h-9.74z" />
    <path d="m31.7 16.23 12.42 12.42a1.25 1.25 0 0 1 0 1.76l-1.02 1.02-14.18-14.18 1.02-1.02a1.25 1.25 0 0 1 1.76 0z" fill="currentColor" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" />
    <path d="M14.9 20.96c.11-.66.77-1.08 1.42-1.23.65-.16 1.33-.12 1.98-.28.56-.14 1.12-.48 1.33-.99a.54.54 0 0 1 .4-.34 3.2 3.2 0 0 1 2.91 1.08c.3.36.5.77.82 1.12.3.34.53 1.27 2.31 1.14" fill="none" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" />
  </svg>
);

export const soapIcon = (
  <svg viewBox="0 0 52.7749 52.7749" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-119.42757 -170.23564)">
      <path d="M158.83275 192.48144a2.86859 2.86859 0 0 0-.45887-4.64296c-.84785-.49025-1.61925-.74084-2.44268-.35264a3.92957 3.92957 0 0 0-1.00306-1.50321 2.87337 2.87337 0 1 0-4.61817-2.78447 2.87094 2.87094 0 0 0-2.98109 4.84187c-.007.0125-.0161.0238-.0236.0364a2.87179 2.87179 0 0 0 2.71664 4.30119 2.86837 2.86837 0 0 0 4.20827 2.98093 2.871 2.871 0 1 0 4.60251-2.87716z" fill="currentColor" stroke="#faf6ef" strokeWidth="1.46778" strokeMiterlimit="10" />
      <rect x="139.11446" y="187.24467" width="14.98875" height="24.04904" rx="3.04131" fill="currentColor" stroke="#faf5ee" strokeWidth="1.31837" strokeLinecap="round" />
      <rect x="143.57283" y="191.10735" width="6.07203" height="16.32368" rx="1.5948" fill="currentColor" stroke="#faf5ee" strokeWidth="1.31837" strokeLinecap="round" />
      <circle cx="133.55457" cy="187.0414" r="2.87324" fill="currentColor" stroke="#faf6ef" strokeWidth="1.46778" strokeMiterlimit="10" />
      <circle cx="138.83972" cy="180.61276" r="1.80467" fill="currentColor" stroke="#faf6ef" strokeWidth="1.46778" strokeMiterlimit="10" />
      <path d="M143.97409 199.1302a2.86359 2.86359 0 0 0-.85703.38161c-.0103-.0507-.0183-.10144-.0312-.15213a2.87322 2.87322 0 1 0-5.56376 1.43735c.0185.0722.0423.14165.0661.21105a2.87166 2.87166 0 0 0-1.25508 5.58647 2.86462 2.86462 0 0 0 1.51167 4.01698 2.87195 2.87195 0 1 0 5.334.59442 2.8702 2.8702 0 0 0 .81563-5.20772 2.87946 2.87946 0 0 0 .56-1.2156 2.8735 2.8735 0 1 0-.58021-5.65243z" fill="currentColor" stroke="#faf6ef" strokeWidth="1.46778" strokeMiterlimit="10" />
    </g>
  </svg>
);

export const skinCancerIcon = (
  <svg viewBox="0 0 52.77 52.77" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-135.54 -178.08)">
      <ellipse cx="148.49" cy="214.42" rx="9.14" ry="7.44" transform="rotate(-3.55)" fill="currentColor" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" strokeDasharray="2.11667,2.64583" />
      <path fill="currentColor" d="m145.25 214.52 33.44-19.31" />
      <g fill="none" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round">
        <path d="m145.25 214.52.92-.53" />
        <path strokeDasharray="2.14688,2.68362" d="m148.49 212.65 28.12-16.24" />
        <path d="m177.77 195.74.92-.53" />
      </g>
      <ellipse cx="156.82" cy="206.49" rx="3.18" ry="3.41" fill="currentColor" />
      <ellipse cx="165.43" cy="203.58" rx="3.18" ry="3.41" fill="currentColor" />
      <path d="M156.56 205.53a5.1 5.1 0 0 1 10.2 0M166.76 205.53c0 2.82-2.28 2.99-5.1 2.99s-5.1-.17-5.1-2.99" fill="currentColor" stroke="#faf5ee" strokeWidth="1.32" strokeLinecap="round" />
    </g>
  </svg>
);

export const intravenousIcon = (
  <svg viewBox="0 0 52.775 52.775" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" stroke="#faf5ee" strokeWidth="1.318" strokeLinecap="round" d="m39.703 37.458-2.245 2.245-4.825-4.825 2.245-2.245z" />
    <path fill="currentColor" stroke="#faf5ee" strokeWidth="1.318" strokeLinecap="round" d="m37.477 30.035-7.442 7.442-1.77-1.77 7.441-7.443zM42.351 37.602l-4.75 4.75-1.397-1.397 4.75-4.75zM33.448 30.455 20.77 17.776c-.52-.519-1.36-.519-1.693-.187l-1.544-1.544-1.488 1.488 1.544 1.544c-.332.332-.332 1.174.187 1.693l12.679 12.678zM16.596 16.596l-4.327-4.327" />
    <path d="M23.676 20.917s-.878 1.124-1.928 1.047c-1.05-.076-1.904-.414-2.192-.077" fill="currentColor" stroke="#faf5ee" strokeWidth="1.318" strokeLinecap="round" />
  </svg>
);

export default { phoneIcon, laptopIcon, cameraIcon };
