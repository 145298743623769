import React from 'react';

import './supply-chain-model.scss';

export const TantalumSupplyChainModel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1100 551.793"
    className="supply-chain-model"
  >
    <defs><clipPath id="supply-chain-model-clip"><path fill="none" d="M0 0h1100v551.793H0z" /></clipPath></defs>
    <path fill="currentColor" d="M274.394 178.578h744.02v4.291h-744.02z" />
    <text transform="translate(411.882 257.332)" className="supply-chain-model-label">
      <tspan x="0" y="0">Exporter</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M489.39 180.726a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M482.073 180.726a22.472 22.472 0 1 1-22.472-22.472 22.471 22.471 0 0 1 22.472 22.472" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(601.102 257.332)" className="supply-chain-model-label">
      <tspan x="0" y="0">Smelter</tspan>
    </text>
    <text transform="translate(591.49 298.802)" className="supply-chain-model-label">
      <tspan x="0" y="0">or refiner</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M675.361 180.726a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M668.044 180.726a22.472 22.472 0 1 1-22.472-22.472 22.471 22.471 0 0 1 22.472 22.472" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(767.827 257.332)" className="supply-chain-model-label">
      <tspan x="0" y="0">Component</tspan>
    </text>
    <text transform="translate(753.728 298.802)" className="supply-chain-model-label">
      <tspan x="0" y="0">manufacturer</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M861.332 180.726a29.79 29.79 0 1 1-29.788-29.79 29.789 29.789 0 0 1 29.789 29.79" fill="currentColor" />
        <path d="M854.013 180.726a22.472 22.472 0 1 1-22.468-22.472 22.471 22.471 0 0 1 22.471 22.472" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(973.019 257.332)" className="supply-chain-model-label">
      <tspan x="0" y="0">Product</tspan>
    </text>
    <text transform="translate(939.697 298.802)" className="supply-chain-model-label">
      <tspan x="0" y="0">manufacturer</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M1047.302 180.726a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M1039.985 180.726a22.472 22.472 0 1 1-22.472-22.472 22.471 22.471 0 0 1 22.472 22.472" fill="#ca986a" />
        <text transform="translate(226.753 545.337)" className="supply-chain-model-label-alt">
          <tspan x="0" y="0">UPSTREAM</tspan>
        </text>
        <text transform="translate(584.69 545.337)" className="supply-chain-model-label-alt">
          <tspan x="0" y="0">CHOKE POINT</tspan>
        </text>
        <text transform="translate(855.705 545.337)" className="supply-chain-model-label-alt">
          <tspan x="0" y="0">DOWNSTREAM</tspan>
        </text>
        <path d="m108.751 238.028-1.353-4.072 160.191-53.231-160.191-53.231 1.353-4.072 172.446 57.3Z" fill="currentColor" />
        <path fill="currentColor" d="m667.368 328.706 1.351-4.071L835.04 379.84l-1.351 4.072z" />
        <path fill="currentColor" d="m831.024 379.875 166.337-55.214 1.351 4.072-166.336 55.214z" />
      </g>
    </g>
    <text data-name="Artisanal &amp;" transform="translate(45.503 310.712)" className="supply-chain-model-label">
      <tspan x="0" y="0">Artisal &amp;</tspan>
    </text>
    <text transform="translate(6.505 352.182)" className="supply-chain-model-label">
      <tspan x="0" y="0">small scale miner</tspan>
    </text>
    <text transform="translate(42.94 37.586)" className="supply-chain-model-label">
      <tspan x="0" y="0">Large scale</tspan>
    </text>
    <text transform="translate(75.21 79.055)" className="supply-chain-model-label"><tspan x="0" y="0">miner</tspan></text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M137.863 234.588a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M130.547 234.588a22.472 22.472 0 1 1-22.473-22.472 22.473 22.473 0 0 1 22.473 22.472" fill="#ca986a" />
        <path d="M137.863 125.458a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M130.547 125.458a22.472 22.472 0 1 1-22.473-22.47 22.473 22.473 0 0 1 22.473 22.47" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(237.24 257.332)" className="supply-chain-model-label">
      <tspan x="0" y="0">Trader</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M303.42 180.726a29.79 29.79 0 1 1-29.79-29.79 29.789 29.789 0 0 1 29.79 29.79" fill="currentColor" />
        <path d="M296.102 180.726a22.472 22.472 0 1 1-22.472-22.472 22.471 22.471 0 0 1 22.472 22.472" fill="#ca986a" />
        <path fill="currentColor" d="M0 517.375h549.892v4.291H0z" />
        <path fill="currentColor" d="M564.496 517.375h159.937v4.291H564.496z" />
        <path fill="currentColor" d="M739.038 517.375H1100v4.291H739.038z" />
      </g>
    </g>
    <text transform="translate(781.878 458.545)" className="supply-chain-model-label">
      <tspan x="0" y="0">Recycler</tspan>
    </text>
    <g>
      <g clipPath="url(#supply-chain-model-clip)">
        <path d="M861.332 381.939a29.79 29.79 0 1 1-29.789-29.79 29.789 29.789 0 0 1 29.789 29.79" fill="currentColor" />
        <path d="M854.013 381.939a22.472 22.472 0 1 1-22.468-22.477 22.471 22.471 0 0 1 22.471 22.472" fill="#ca986a" />
        <path d="m359.09 195.784-2.59-3.423 15.378-11.636-15.378-11.637 2.59-3.423 19.9 15.059Z" fill="currentColor" />
        <path d="m546.281 195.784-2.59-3.423 15.378-11.636-15.375-11.637 2.59-3.423 19.9 15.059Z" fill="currentColor" />
        <path d="m733.471 195.784-2.591-3.423 15.378-11.636-15.378-11.637 2.591-3.423 19.9 15.059Z" fill="currentColor" />
        <path d="m920.661 195.784-2.591-3.423 15.378-11.636-15.377-11.637 2.591-3.423 19.9 15.059Z" fill="currentColor" />
        <path d="m942.507 360.945-23.64-8 14.124-20.576 3.536 2.43-10.914 15.9 18.269 6.182Z" fill="currentColor" />
        <path d="m170.554 162.275-1.376-4.065 18.268-6.182-10.914-15.899 3.537-2.43 14.124 20.576Z" fill="currentColor" />
        <path d="m735.266 364.279-14.275-20.47 23.58-8.175 1.4 4.055-18.222 6.318 11.033 15.817Z" fill="currentColor" />
        <path d="m179.899 227.773-3.52-2.455 11.03-15.818-18.22-6.317 1.4-4.055 23.579 8.175Z" fill="currentColor" />
      </g>
    </g>
  </svg>
);

export const SesameSupplyChainModel = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1166.22 482.07">
    <defs><clipPath id="a"><path data-name="Rectangle 486" fill="none" d="M0 0h1165.79v552.07H0z" /></clipPath></defs>
    <path data-name="Path 1630" d="m212.07 236.11-166.4-55.3 166.4-55.3" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <text transform="translate(18.48 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0" letterSpacing="0em">S</tspan>
      <tspan y="0">eed</tspan>
    </text>
    <text transform="translate(0 300.3)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700"><tspan x="0" y="0">supplier</tspan></text>
    <path data-name="Path 1631" d="M16.63 180.82a29.8 29.8 0 1 0 29.8-29.8 29.8 29.8 0 0 0-29.8 29.8" fill="#faf6ef" />
    <path data-name="Path 1632" d="M23.95 180.82a22.48 22.48 0 1 0 22.49-22.49 22.48 22.48 0 0 0-22.49 22.49" fill="#ca986a" />
    <path data-name="Path 1633" d="m122 140.63 20.96 7.1-12.52 18.25" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1634" d="m130.4 195.64 12.66 18.15-20.91 7.25" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Line 277" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" d="M1122.4 180.82H378.03" />
    <path data-name="Rectangle 485" fill="none" d="M0 0h1165.79v552.07H0z" />
    <text transform="translate(515.58 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0">Expo</tspan>
      <tspan y="0" letterSpacing=".01em">r</tspan>
      <tspan y="0" letterSpacing="0em">t</tspan>
      <tspan y="0">er</tspan>
    </text>
    <g data-name="Group 487">
      <g data-name="Group 486" clipPath="url(#a)">
        <path data-name="Path 1635" d="M593.12 180.82a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
        <path data-name="Path 1636" d="M585.8 180.82a22.48 22.48 0 1 1-22.48-22.49 22.48 22.48 0 0 1 22.48 22.49" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(690.51 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0">P</tspan>
      <tspan y="0" letterSpacing="0em">r</tspan>
      <tspan y="0">ocessor</tspan>
    </text>
    <g data-name="Group 489">
      <g data-name="Group 488" clipPath="url(#a)">
        <path data-name="Path 1637" d="M779.18 180.82a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
        <path data-name="Path 1638" d="M771.86 180.82a22.48 22.48 0 1 1-22.48-22.49 22.48 22.48 0 0 1 22.48 22.49" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(858.07 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0" letterSpacing="0em">M</tspan>
      <tspan y="0">an</tspan>
      <tspan y="0" letterSpacing=".01em">u</tspan>
      <tspan y="0" letterSpacing=".01em">f</tspan>
      <tspan y="0">actu</tspan>
      <tspan y="0" letterSpacing="-.01em">r</tspan>
      <tspan y="0">er</tspan>
    </text>
    <path data-name="Path 1639" d="M965.25 180.82a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
    <path data-name="Path 1640" d="M957.93 180.82a22.48 22.48 0 1 1-22.48-22.49 22.48 22.48 0 0 1 22.48 22.49" fill="#ca986a" />
    <text transform="translate(1077.22 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700"><tspan x="0" y="0">Retailer</tspan></text>
    <path data-name="Path 1641" d="M1151.31 180.82a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
    <path data-name="Path 1642" d="M1144 180.82a22.48 22.48 0 1 1-22.5-22.49 22.48 22.48 0 0 1 22.5 22.49" fill="#ca986a" />
    <text transform="translate(186.65 442.78)" fill="#faf6ef" fontSize="17.17" className="supply-chain-model-label-alt">
      <tspan x="0" y="0" letterSpacing=".06em">UPSTREA</tspan>
      <tspan y="0">M</tspan>
    </text>
    <text data-name="CHOKE POINT" transform="translate(501.23 442.78)" fill="#faf6ef" fontSize="17.17" className="supply-chain-model-label-alt">
      <tspan x="0" y="0" letterSpacing=".06em">C</tspan>
      <tspan y="0" letterSpacing=".06em">HO</tspan>
      <tspan y="0" letterSpacing=".06em">K</tspan>
      <tspan y="0" letterSpacing=".06em">E </tspan>
      <tspan y="0" letterSpacing=".06em">P</tspan>
      <tspan y="0" letterSpacing=".06em">OIN</tspan>
      <tspan y="0">T</tspan>
    </text>
    <text transform="translate(846.78 442.78)" fill="#faf6ef" fontSize="17.17" className="supply-chain-model-label-alt">
      <tspan x="0" y="0" letterSpacing=".06em">D</tspan>
      <tspan y="0" letterSpacing=".05em">O</tspan>
      <tspan y="0" letterSpacing=".06em">WN</tspan>
      <tspan y="0" letterSpacing=".06em">S</tspan>
      <tspan y="0" letterSpacing=".06em">TREA</tspan>
      <tspan y="0">M</tspan>
    </text>
    <path data-name="Path 1643" d="m211.61 236.11 166.4-55.3-166.4-55.3" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Rectangle 488" fill="none" d="M0 0h1165.79v552.07H0z" />
    <text transform="translate(144.18 312.2)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0">S</tspan>
      <tspan y="0" letterSpacing="-.01em">m</tspan>
      <tspan y="0">allholder</tspan>
    </text>
    <text transform="translate(172.71 353.69)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0" letterSpacing=".01em">f</tspan>
      <tspan y="0">a</tspan>
      <tspan y="0" letterSpacing=".01em">r</tspan>
      <tspan y="0">mer</tspan>
    </text>
    <text data-name="Large scale" transform="translate(146.43 38.94)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0" letterSpacing="-.01em">L</tspan>
      <tspan y="0">a</tspan>
      <tspan y="0" letterSpacing="-.01em">r</tspan>
      <tspan y="0">ge s</tspan>
      <tspan y="0" letterSpacing="0em">c</tspan>
      <tspan y="0">ale</tspan>
    </text>
    <text data-name="farmer" transform="translate(172.71 80.43)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700">
      <tspan x="0" y="0" letterSpacing=".01em">f</tspan>
      <tspan y="0">a</tspan>
      <tspan y="0" letterSpacing=".01em">r</tspan>
      <tspan y="0">mer</tspan>
    </text>
    <g data-name="Group 493">
      <g data-name="Group 492" clipPath="url(#a)">
        <path data-name="Path 1644" d="M241.42 234.7a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
        <path data-name="Path 1645" d="M234.1 234.7a22.48 22.48 0 1 1-22.48-22.48 22.48 22.48 0 0 1 22.48 22.49" fill="#ca986a" />
        <path data-name="Path 1646" d="M241.42 125.52a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
        <path data-name="Path 1647" d="M234.1 125.52a22.48 22.48 0 1 1-22.48-22.48 22.48 22.48 0 0 1 22.48 22.48" fill="#ca986a" />
      </g>
    </g>
    <text transform="translate(340.85 258.8)" fill="#faf6ef" fontSize="45.8" className="supply-chain-model-label" fontWeight="700"><tspan x="0" y="0">Trader</tspan></text>
    <path data-name="Path 1648" d="M407.06 180.82a29.8 29.8 0 1 1-29.8-29.8 29.8 29.8 0 0 1 29.8 29.8" fill="#faf6ef" />
    <path data-name="Path 1649" d="M399.74 180.82a22.48 22.48 0 1 1-22.49-22.49 22.48 22.48 0 0 1 22.49 22.49" fill="#ca986a" />
    <path data-name="Line 278" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" d="M.69 414.78H466.4" />
    <path data-name="Line 279" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" d="M481.02 414.78h160.02" />
    <path data-name="Line 280" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" d="M655.65 414.78h509.91" />
    <path data-name="Path 1650" d="m461.46 167.46 17.65 13.36-17.65 13.35" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1651" d="m648.75 167.46 17.64 13.36-17.64 13.35" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1652" d="m836.03 167.46 17.65 13.36-17.65 13.35" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1653" d="m1023.31 167.46 17.65 13.36-17.65 13.35" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1654" d="m273.44 160.32 20.96-7.1-12.52-18.24" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Path 1655" d="m281.71 226.66 12.66-18.15-20.9-7.25" fill="none" stroke="#faf6ef" strokeMiterlimit="10" strokeWidth="4.29" />
    <path data-name="Rectangle 490" fill="none" d="M0 0h1165.79v552.07H0z" />

  </svg>
);

export default { TantalumSupplyChainModel, SesameSupplyChainModel };
