import React, { useState, useEffect, useContext } from 'react';
import { useInView } from 'react-hook-inview';
import MapDiagram, { MapHighlightColor } from '../map';
import CircularDiagram from '../circular-diagram';

import './interactive-map.scss';
import { textContentFunction } from '../../utils/text';
import IndustryContext from '../industry-context';

interface CountriesSectionProps {
  sectionId: string
  sectionCountries: string[]
  sectionColor: MapHighlightColor
  children: React.ReactNode
  setCountries: (a: string[]) => void
  setHighlightColor: (a: MapHighlightColor) => void
}

const CountriesSection = (
  {
    sectionCountries, children, setCountries, setHighlightColor, sectionColor,
    sectionId,
  }: CountriesSectionProps,
) => {
  const [refOuter, isOuterVisible] = useInView({ threshold: 0.5 });
  const [refInner, isInnerVisible] = useInView({ threshold: 1, rootMargin: '-25%' });

  useEffect(() => {
    if (isOuterVisible || isInnerVisible) {
      setCountries(sectionCountries);
      setHighlightColor(sectionColor);
    }
  }, [isOuterVisible, isInnerVisible]);

  return (
    <div ref={refOuter} id={`im-${sectionId}`}>
      <div ref={refInner} />
      {children}
    </div>
  );
};

const ORIGIN_COUNTRIES_TANTALUM = ['Australia', 'Bolivia', 'Brazil', 'Burundi',
  'China', 'Democratic Republic of the Congo', 'Ethiopia', 'Mozambique', 'Nigeria',
  'Russia', 'Rwanda', 'Uganda'];

const PROCESSING_COUNTRIES_TANTALUM = [
  'Brazil', 'China ', 'Germany', 'Japan', 'USA', 'England', 'India', 'Taiwan',
  'France', 'Italy ', 'Sweden', 'Switzerland', 'South Korea', 'Belgium',
];

const RETAIL_COUNTRIES_TANTALUM = ['United States of America', 'Germany',
  'China', 'France', 'England', 'Belgium', 'Italy', 'Canada', 'Spain',
  'Australia', 'South Korea', 'Netherlands', 'Saudi Arabia', 'United Arab Emirates',
  'Japan', 'Switzerland', 'Poland', 'Sweden', 'Austria', 'Turkey',
  'Mexico', 'Hong Kong', 'Singapore', 'Vietnam', 'Malaysia', 'Thailand', 'India',
  'Chech Republic', 'United Arab Emirates', 'Russia'];

const ORIGIN_COUNTRIES_SESAME = ['Sudan', 'India', 'Ethiopia', 'Myanmar',
  'Nigeria', 'United Republic of Tanzania'];

const PROCESSING_COUNTRIES_SESAME = ['China', 'Japan', 'India', 'South Korea',
  'Israel', 'Iran', 'Egypt', 'USA', 'Germany', 'Saudi Arabia'];

const InteractiveMap = ({ getText }: { getText: textContentFunction }) => {
  const [countries, setCountries] = useState([] as string[]);
  const [highlightColor, setHighlightColor] = useState('red' as MapHighlightColor);
  const { industry } = useContext(IndustryContext);

  const resourceName = (industry === 'agriculture') ? 'Sesame' : 'Tantalum';

  const getPrefixText = (name: string) => getText(`${resourceName} ${name}`);

  useEffect(() => {
    setCountries(['']);
  }, [industry]);

  return (
    <div className="interactive-map">
      <div className="interactive-map-content">
        <CountriesSection
          sectionId="origin"
          sectionCountries={industry === 'mining' ? ORIGIN_COUNTRIES_TANTALUM : ORIGIN_COUNTRIES_SESAME}
          setCountries={setCountries}
          setHighlightColor={setHighlightColor}
          sectionColor="green"
        >
          {getPrefixText('Origin')}
        </CountriesSection>
        <CountriesSection
          sectionId="processing"
          sectionCountries={industry === 'mining' ? PROCESSING_COUNTRIES_TANTALUM : PROCESSING_COUNTRIES_SESAME}
          setCountries={setCountries}
          setHighlightColor={setHighlightColor}
          sectionColor="purple"
        >
          {getPrefixText('Processing')}
          {getPrefixText('Manufacturing and Assembly')}
        </CountriesSection>
        <CountriesSection
          sectionId="consumer"
          sectionCountries={industry === 'mining' ? RETAIL_COUNTRIES_TANTALUM : []}
          setCountries={setCountries}
          setHighlightColor={setHighlightColor}
          sectionColor="red"
        >
          {getPrefixText('Retail')}
        </CountriesSection>
      </div>
      <div className="interactive-map-side">
        <div className="interactive-map-side-inner">
          <div className="interactive-map-side-inner-map">
            <MapDiagram countries={countries} color={highlightColor} />
          </div>
          <div className="interactive-map-side-inner-diagram">
            <CircularDiagram highlightColor={highlightColor} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveMap;
