import React from 'react';

import './recs.scss';

const RecsLayout = ({ children }: {children: React.ReactNode}) => (
  <div className="recs-layout">
    {children}
  </div>
);

export default RecsLayout;
