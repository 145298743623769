import React from 'react';

import './snake-layout.scss';

const SnakeLayout = ({ children }: {children: React.ReactNode}) => (
  <div className="snake-layout">
    {children}
  </div>
);

export default SnakeLayout;
