import React from 'react';

const TitleVector = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1469 799"
    className="hero-title-vector"
  >
    <text transform="translate(0 275)" fontSize="220">
      <tspan x="0" y="0">CHILD</tspan>
      <tspan x="0" y="180">LABOUR IN</tspan>
    </text>
    <text transform="translate(1469 573)" fontSize="220">
      <tspan x="-187" y="0">THE</tspan>
      <tspan x="-742" y="180">SUPPLY CHAIN</tspan>
    </text>
    <path d="M67 84V4h1320v386" className="hero-title-vector-top-line" />
    <path d="M700 716H67V485.17" className="hero-title-vector-bottom-line" />
  </svg>
);

export default TitleVector;
