import React from 'react';

import './intervention-indicator.scss';

interface InterventionIndicatorProps {
  label: string
  active?: boolean
}

const InterventionIndicator = ({ label, active }: InterventionIndicatorProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 110.2 110.2"
    className={`intervention-indicator${active ? ' is-active' : ''}`}
  >
    <path className="intervention-indicator-bg" d="M0 55 55 0l55 55-55 55z" />
    <path className="intervention-indicator-fg" d="m11 55 44-44 44 44-44 44z" />
    <text x="55" y="75">{label}</text>
  </svg>
);

InterventionIndicator.defaultProps = {
  active: false,
};

export default InterventionIndicator;
