import React from 'react';

const MainCircle = (
  <svg
    className="lld-main-circle"
    viewBox="0 0 249.025 234.826"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="124.3125"
      cy="111.113"
      r="110.713"
      stroke="currentColor"
      fill="none"
      strokeWidth="0.75"
      className="lld-main-circle-outer"
    />

    <circle
      cx="124.3125"
      cy="111.113"
      r="94"
      fill="currentColor"
    />

  </svg>
);

export default MainCircle;
